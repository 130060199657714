import { useEffect, useState, useRef } from "react";
import stylesD from "../AutomationTest/projectdetails.module.css";
import stylesC from "../AutomationTest/testChat.module.css";
import styles from "../AutomationTest/teststrategy.module.css";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import ReactQuill from "react-quill";
import { marked } from "marked";
import download from "../assests/images/pluginicons/downlaod.png";
import { jsPDF } from "jspdf";
import logo from "../assests/images/appstore/logo.png";
import downArrow from "../assests/images/reactflow/downArrow.png";
import arrow from "../assests/images/appstore/arrow.png";
// import image from "../../../assests/images/appstore/image.png";
import image from "../assests/images/sidebar/smartChat.svg";
import cx from "classnames";
import {
  generate_test_plans,
  get_all_test_plans,
  PostTeststrategy,
  Create_test_case,
  Delete_Testcase,
  Delete_Testscript,
  generate_test_cases,
  generate_test_script,
  get_all_test_cases,
  modify_test_case,
  save_test_script,
  get_output,
  fetchTestCaseType,
  addtesttags,
  removeTestTags,
  Querychatbot,
  Querychatbothistory,
} from "../commonApi";
import Charging from "../Charging";
import { Toast } from "primereact/toast";
import remarkGfm from "remark-gfm";
import ProjectDetails from "./ProjectDetails";
import NewLoading from "../components/loading";

const TestStrategy = () => {
  const { project_id } = useParams();
  const [open, setOpen] = useState(false);
  // const [Isgenerated, setIgenerated] = useState(false);
  // console.log("------------------", Isgenerated);
  const [isGenerated, setIsGenerated] = useState(false);
  const [viewPlan, setViewPlan] = useState(false);
  const [stretegy, setStretegy] = useState(true);
  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const responseData = location.state?.responseData || []; // Ensure responseData is an array or default to empty array
  const projectData = location.state?.projectData;
  // const [responseData, setResponseData] = useState(null);
  const [applicationname, setApplicationname] = useState(null);
  console.log("Received projectData:", projectData.project_id);
  console.log("Received responseData:");
  // console.log("--------isgener", Isgenerated);
  const [storydata, setStorydata] = useState([]);
  ///////////////////////////
  const [isModalOpenn, setModalOpenn] = useState(false);
  const [testCaseId, setTestCaseId] = useState("");
  const [testCaseTitle, setTestCaseTitle] = useState("");
  const [preconditions, setPreconditions] = useState("");
  const [testSteps, setTestSteps] = useState("");
  const [featuresdata, setFeatures] = useState("");
  // const projectData = location.state?.projectData;
  console.log("------------projectdata", projectData);

  // const { project_id } = useParams();
  const [testCasesByFeature, setTestCasesByFeature] = useState({});

  const [editingTestCase, setEditingTestCase] = useState(null);

  const [testData, setTestData] = useState("");
  const [expectedResult, setExpectedResult] = useState("");
  const [currentFeatureId, setCurrentFeatureId] = useState(null); // For tracking the feature ID
  const [openTestScripts, setOpenTestScripts] = useState({});
  const [editingTestScriptId, setEditingTestScriptId] = useState(null);
  const [editedTestScriptContent, setEditedTestScriptContent] = useState("");
  const [expandedFeatures, setExpandedFeatures] = useState({});
  const [openTestScriptCaseId, setOpenTestScriptCaseId] = useState(null);
  const [Testplan, setTestplan] = useState("");
  const [showout, setshowout] = useState(false);
  const [output, setoutput] = useState("");

  useEffect(() => {
    // Fetch initial test strategy status when component mounts
    fetchTestStrategy();
  }, []);

  const fetchTestStrategy = async () => {
    try {
      const strategyData = { project_id: projectData.project_id };
      const strategyResponse = await PostTeststrategy(strategyData);
      if (strategyResponse.success && strategyResponse.data.length > 0) {
        setIsGenerated(strategyResponse.data[0].is_test_plan_generated);
        setApplicationname(strategyResponse.data[0].application_name);
        if (strategyResponse.data[0].is_test_plan_generated) {
          fetchData();
        }
      } else {
        console.log("Failed to fetch test strategy", strategyResponse.message);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: strategyResponse.message,
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching test strategy:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
    }
  };

  //   (item) => item.is_test_plan_generated
  // );
  // setIgenerated(isGenerated);

  function downloadResponse(title, text) {
    let doc = new jsPDF();
    doc.setTextColor(0, 0, 0);
    doc.setFont("times", "normal");
    doc.addImage(logo, "PNG", 155, 10, 40, 10, "logo", "NONE");

    let pageNumber = 1;
    let yPosition = 35;

    function addBorders() {
      let margin = 5;
      doc.rect(
        margin,
        margin,
        doc.internal.pageSize.getWidth() - 2 * margin,
        doc.internal.pageSize.getHeight() - 2 * margin
      );
    }

    function addLogo() {
      doc.addImage(logo, "PNG", 155, 10, 40, 10, "logo", "NONE");
    }

    function addNewPage() {
      doc.addPage("a4", "p");
      addBorders();
      addLogo();
      yPosition = 35;
      pageNumber++;
    }

    function processText(text) {
      const lines = doc.splitTextToSize(text.trim(), 240);
      for (let line of lines) {
        const strongRegex = /\*\*(.+?)\*\*/g;
        const bulletRegex = /^\s*-/;
        const headingRegex = /^#{1,3}\s+(.*)/;

        if (bulletRegex.test(line)) {
          line = line.replace(bulletRegex, "•");
        }

        let headingMatch = line.match(headingRegex);
        if (headingMatch) {
          let headingLevel = line.match(/^#+/)[0].length;
          let fontSize = headingLevel === 1 ? 16 : headingLevel === 2 ? 14 : 12;
          doc.setFont("helvetica", "bold");
          doc.setFontSize(fontSize);
          doc.text(15, yPosition, headingMatch[1]);
          doc.setFontSize(12);
          doc.setFont("times", "normal");
        } else {
          doc.setFont("times", "normal");
          let xPos = 15;
          let segments = line.split(strongRegex);
          segments.forEach((segment, index) => {
            if (index % 2 === 1) {
              doc.setFont("helvetica", "bold");
            } else {
              doc.setFont("times", "normal");
            }
            doc.text(xPos, yPosition, segment);
            xPos += doc.getTextWidth(segment);
          });
        }

        yPosition += 7.5;
        if (yPosition >= 280) {
          addNewPage();
        }
      }
    }

    function processTable(tableText) {
      const rows = tableText
        .split("\n")
        .map((row) => row.split("|").filter((cell) => cell.trim()));
      const columnCount = Math.max(...rows.map((row) => row.length));
      const columnWidths = new Array(columnCount).fill(
        (210 - 10) / columnCount
      );

      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");

      rows.forEach((row, rowIndex) => {
        const cellHeight = 10;
        if (yPosition + cellHeight > 280) {
          addNewPage();
        }

        let xPosition = 15;
        row.forEach((cell, cellIndex) => {
          const width = columnWidths[cellIndex];
          // Add error checking
          if (
            typeof width === "number" &&
            !isNaN(width) &&
            width > 0 &&
            typeof cellHeight === "number" &&
            !isNaN(cellHeight) &&
            cellHeight > 0
          ) {
            try {
              doc.rect(xPosition, yPosition, width, cellHeight);
            } catch (error) {
              console.error("Error in doc.rect:", error);
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: error,
                life: 3000,
              });
              console.log("Params:", xPosition, yPosition, width, cellHeight);
            }
          } else {
            console.error(
              "Invalid dimensions:",
              xPosition,
              yPosition,
              width,
              cellHeight
            );
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Invalid dimensions:",
              life: 3000,
            });
          }
          doc.text(cell.trim(), xPosition + 2, yPosition + 7);
          xPosition += width;
        });

        if (rowIndex === 0 || rowIndex === 1) {
          doc.setFont("helvetica", "bold");
        } else {
          doc.setFont("times", "normal");
        }

        yPosition += cellHeight;
      });

      yPosition += 5; // Add some space after the table
      doc.setFontSize(12);
      doc.setFont("times", "normal");
    }

    addBorders();
    doc.text(
      `${title.replace("_", " ")} Document`,
      105,
      25,
      null,
      null,
      "center"
    );

    const sections = text.split(/(\n\|.*\|.*\|[\s\S]*?\n\n)/);
    sections.forEach((section) => {
      if (section.trim().startsWith("|") && section.trim().endsWith("|")) {
        processTable(section);
      } else {
        processText(section);
      }
    });

    for (let i = 1; i <= pageNumber; i++) {
      doc.setPage(i);
      doc.text(`${i}`, 105, 290, null, null, "center");
    }

    doc.save(`${title}.pdf`);
  }

  const handleDownload = () => {
    const title = "Test Strategy";

    // If responseData is an array, combine all test_strategy fields into one text block
    const text = responseData
      .map((response) => response?.test_strategy)
      .join("\n\n"); // Joins multiple test_strategy with newlines if it's an array

    console.log("------------text", text);

    downloadResponse(title, text);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [selectedStoryvar, setSelectedStoryvar] = useState(false);
  // Function to handle when a user clicks on a story
  const handleStoryClick = (story) => {
    setSelectedStory(story);
    setViewPlan(false);
    setStretegy(false);
    setSelectedStoryvar(true);
    setSelectedTestCase(null);
    setscript(false);
  };

  // State to store the input values
  const [formValues, setFormValues] = useState({
    jiraServer: "",
    jiraEmail: "",
    jiraApiToken: "",
    jiraProjectKey: "",
  });

  // Open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const stretegyClick = () => {
    setStretegy(true);
    setViewPlan(false);
    setSelectedStory(null);
    setSelectedStoryvar(false);
    setSelectedTestCase(null);
    setscript(false);
  };

  const [isGenerating, setIsGenerating] = useState(false);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsGenerating(true); // Show spinner when form is submitted
    localStorage.setItem(`isGenerating_${projectData.project_id}`, "true"); // Save progress state for specific project

    // Prepare the data for the API call
    const data = {
      project_id: projectData.project_id,
      JIRA_SERVER: formValues.jiraServer,
      JIRA_EMAIL: formValues.jiraEmail,
      JIRA_API_TOKEN: formValues.jiraApiToken,
      JIRA_project_key: formValues.jiraProjectKey,
    };

    try {
      handleCloseModal();
      // Call the generate_test_plans API
      const response = await generate_test_plans(data);

      console.log("API Response:", response);
      await fetchTestStrategy();
    } catch (error) {
      console.error("Error in generating test plans:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while generating test plans",
        life: 3000,
      });
    } finally {
      setIsGenerating(false); // Stop spinner after API call completes
      localStorage.removeItem(`isGenerating_${projectData.project_id}`); // Remove progress state
      handleCloseModal();
    }
  };

  const [isOpenfol, setIsOpenfol] = useState({
    project: true,
    folder1: false,
    folder2: false,
  });

  const toggleFolder = (folderName) => {
    setIsOpenfol((prev) => ({
      ...prev,
      [folderName]: !prev[folderName],
    }));
  };

  const handleViewTestPlan = async () => {
    setViewPlan(true);
    setStretegy(false);
    setSelectedStory(null);
    setSelectedStoryvar(false);
    setSelectedStory(null);
    setscript(false);
   
  };
  ////////////////////////////
  const handleEditClick = (testCase) => {
    setEditingTestCase(testCase);
  };

  const handleEditTestScript = (testCase) => {
    setEditingTestScriptId(testCase.Test_Case_ID);
    setEditedTestScriptContent(testCase.Test_Script || "");
  };

  const handleDownloadTestScript = (testCase) => {
    // Determine the test script content
    const text =
      editingTestScriptId === testCase.Test_Case_ID
        ? editedTestScriptContent
        : testCase.Test_Script;

    console.log("Text to be added to file:", text); // Debugging line

    if (!text) {
      console.error("No test script content available to download.");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "No test script content available to download.",
        life: 3000,
      });
      return;
    }

    // Determine the file extension based on the language
    let fileExtension;

    if (testCase.language === "python") {
      fileExtension = "py";
    } else if (testCase.language === "java") {
      fileExtension = "java";
    } else {
      console.error("Unsupported language; no file will be downloaded.");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Unsupported language; no file will be downloaded.",
        life: 3000,
      });
      return; // Exit if language is neither python nor java
    }

    // Create a blob with the text content
    const blob = new Blob([text], { type: "text/plain;charset=utf-8" });

    // Create an object URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const a = document.createElement("a");
    a.href = url;
    a.download = `test_script_${testCase.Test_Case_ID}.${fileExtension}`; // Use the determined file extension

    // Append the link to the body
    document.body.appendChild(a);

    // Programmatically click the link to trigger the download
    a.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (Array.isArray(featuresdata)) {
      const initialExpandedState = {};
      featuresdata.forEach((feature) => {
        initialExpandedState[feature.test_plan_id] = false;
      });
      setExpandedFeatures(initialExpandedState);
    } else {
      console.error("featuresdata is not an array:", featuresdata);
      // toast.current.show({
      //   severity: "error",
      //   summary: "Error",
      //   detail:  "featuresdata is not an array",
      //   life: 3000,
      // });
    }
  }, [featuresdata]);

  const handleSaveTestScript = async (testCase) => {
    setOpen(true); // Show loading indicator
    try {
      const data = {
        tc_id: testCase.tc_id,
        test_script: editedTestScriptContent,
      };
      const response = await save_test_script(data);

      if (response.success) {
        // Fetch updated test cases for the feature
        const testCasesResponse = await get_all_test_cases({
          test_plan_id: testCase.test_plan_id,
        });

        if (testCasesResponse.success) {
          setTestCasesByFeature((prev) => ({
            ...prev,
            [testCase.test_plan_id]: testCasesResponse.features,
          }));
        } else {
          console.error(
            "Failed to fetch test cases:",
            testCasesResponse.message
          );

          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to fetch updated test cases",
          });
          return;
        }

        // Clear editing state
        setEditingTestScriptId(null);
        setEditedTestScriptContent("");

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Test script saved successfully",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to save test script",
        });
      }
    } catch (error) {
      console.error("Error saving test script:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while saving the test script",
      });
    } finally {
      setOpen(false);
    }
  };
  const [outputs, setOutputs] = useState({});

  const handleExecuteScript = (testCaseId, scriptOutput) => {
    // Store output specific to each test case
    setOutputs((prevOutputs) => ({
      ...prevOutputs,
      [testCaseId]: scriptOutput,
    }));
  };
  const handleExecute = async (testscript, testCaseId) => {
    setOpen(true); // Show loading indicator
    try {
      const data = {
        test_script: testscript,
      };
      const result = await get_output(data);

      if (result.success) {
        // Update the output for the specific test case
        setOutputs((prevOutputs) => ({
          ...prevOutputs,
          [testCaseId]: result.response, // Store output based on testCaseId
        }));
        setshowout(true); // Show the output section
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: result.message || "Failed to execute test script",
        });
      }
    } catch (error) {
      console.error("Error executing test script:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while executing the test script",
      });
    } finally {
      setOpen(false);
    }
  };

  const handleAddTestCaseClick = (featureId) => {
    setModalOpenn(true);

    setCurrentFeatureId(featureId);
  };

  const fetchData = async () => {
    setOpen(true);
    try {
      const featuresResult = await get_all_test_plans({
        project_id: projectData.project_id,
      });
      if (featuresResult.success) {
        setFeatures(featuresResult.data); // Store feature data
        console.log("------------feteature result", featuresdata);
        setStorydata(featuresResult.data);
        setTestplan(featuresResult.test_plan.test_plan);
        // Initialize openTestScripts
        console.log("---------------ssfcsdc");
        let initialOpenTestScripts = {};

        // Automatically load test cases for features where they have already been generated
        for (const feature of featuresResult.data) {
          if (feature.is_test_case_generated) {
            const testCasesResponse = await get_all_test_cases({
              test_plan_id: feature.test_plan_id,
            });
            console.log("---------------tescaseresponse", testCasesResponse);
            if (testCasesResponse.success) {
              const testCases = testCasesResponse.features;
              setTestCasesByFeature((prev) => ({
                ...prev,
                [feature.test_plan_id]: testCases,
              }));
              console.log("------------testcasesfeature", testCasesByFeature);

              // Initialize openTestScripts based on is_test_script_generated
              testCases.forEach((tc) => {
                if (tc.is_test_script_generated) {
                  initialOpenTestScripts[tc.Test_Case_ID] = true;
                }
              });
            }
          }
        }

        setOpenTestScripts(initialOpenTestScripts);
      } else {
        console.error("Failed to fetch features:", featuresResult.message);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: featuresResult.message,
          life: 3000,
        });
      }
    } catch (error) {
      // console.error("Error fetching features:", error);
      // toast.current.show({
      //   severity: "error",
      //   summary: "Error",
      //   detail: error,
      //   life: 3000,
      // });
    } finally {
      setOpen(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [projectData.project_id]);



  const handleTestCaseChange = (e, featureId, idx, field) => {
    // Create a deep copy of the test cases array
    const updatedTestCases = [...testCasesByFeature[featureId]];

    let value = e.target.value;
    if (field === "Test_Steps") {
      value = value.split("\n");
    } else if (field === "Test_Data") {
      value = parseTestData(value);
    }

    // Update the specific test case immutably
    updatedTestCases[idx] = {
      ...updatedTestCases[idx],
      [field]: value,
    };

    // Update state immutably
    setTestCasesByFeature((prev) => ({
      ...prev,
      [featureId]: updatedTestCases,
    }));
  };

  const handleSaveTestCase = async (featureId, idx) => {
    setOpen(true);
    try {
      const testCase = testCasesByFeature[featureId][idx];
      const data = {
        tc_id: testCase.tc_id,
        Test_Case_ID: testCase.Test_Case_ID,
        Test_Case_Title: testCase.Test_Case_Title,
        Preconditions: testCase.Preconditions,
        Test_Steps: testCase.Test_Steps,
        Test_Data: testCase.Test_Data,
        Expected_result: testCase.Expected_result,
      };

      const response = await modify_test_case(data);

      if (response.success) {
        // Update local state
        setTestCasesByFeature((prev) => ({
          ...prev,
          [featureId]: prev[featureId].map((tc) =>
            tc.Test_Case_ID === testCase.Test_Case_ID ? testCase : tc
          ),
        }));

        // Refresh all features
        const featuresResult = await get_all_test_plans({
          project_id: projectData.project_id,
        });
        if (featuresResult.success) {
          setFeatures(featuresResult.data);
        }

        // Clear the editing state after a successful save
        setEditingTestCase(null);

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Test case updated successfully",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update test case",
        });
      }
    } catch (error) {
      console.error("Error saving test case:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while saving the test case",
      });
    } finally {
      setOpen(false);
    }
  };

  const handleDeleteTestCase = async (testCase) => {
    setOpen(true);
    try {
      const response = await Delete_Testcase({ tc_id: testCase.tc_id });
      if (response.success) {
        // Remove the deleted test case from the local state
        setTestCasesByFeature((prev) => ({
          ...prev,
          [testCase.test_plan_id]: prev[testCase.test_plan_id].filter(
            (tc) => tc.tc_id !== testCase.tc_id
          ),
        }));

        // Refresh all features
        const featuresResult = await get_all_test_plans({
          project_id: projectData.project_id,
        });
        if (featuresResult.success) {
          setFeatures(featuresResult.data);
        }

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Test case deleted successfully",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete test case",
        });
      }
    } catch (error) {
      console.error("Error deleting test case:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while deleting the test case",
      });
    } finally {
      setOpen(false);
    }
  };
  const [testCasesGenerated, setTestCasesGenerated] = useState({}); // Track the status for each story
  
  const [testCasesLoading, setTestCasesLoading] = useState(() => {
    // Load from local storage when the component mounts
    const savedLoadingState = localStorage.getItem('testCasesLoading');
    return savedLoadingState ? JSON.parse(savedLoadingState) : {};
  });

  // Save loading state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('testCasesLoading', JSON.stringify(testCasesLoading));
  }, [testCasesLoading]);

  const handleGenerateTestCases = async (projectId, featureId, storyId) => {
    setTestCasesLoading((prev) => ({
      ...prev,
      [featureId]: true, // Mark this story as loading
    }));
    // Assuming this manages a loading indicator

    try {
      const generationResponse = await generate_test_cases({
        project_id: projectId,
        test_plan_id: featureId,
      });

      if (generationResponse.success) {
        // Update the is_test_case_generated flag for the feature in featuresdata
        setFeatures((prevFeaturesdata) =>
          prevFeaturesdata.map((feature) =>
            feature.test_plan_id === featureId
              ? { ...feature, is_test_case_generated: true }
              : feature
          )
        );

        const testCasesResponse = await get_all_test_cases({
          test_plan_id: featureId,
        });

        setTestCasesGenerated((prevState) => ({
          ...prevState,
          [featureId]: true, // Mark this story's test cases as generated
        }));

        if (testCasesResponse.success) {
          setTestCasesByFeature((prev) => ({
            ...prev,
            [featureId]: testCasesResponse.features,
          }));
        } else {
          console.error(
            "Failed to fetch test cases:",
            testCasesResponse.message
          );
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: testCasesResponse.message,
          });
        }
      } else {
        console.error(
          "Failed to generate test cases:",
          generationResponse.message
        );
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: generationResponse.message,
        });
      }
    } catch (e) {
      console.error("Error:", e);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: e.message,
      });
    } finally {
      setTestCasesLoading((prev) => ({
        ...prev,
        [featureId]: false, // Stop the loading spinner for this story
      }));

    }
  };

  const handleClosenModal = () => {
    setModalOpenn(false);
  };

  const fetchTestCasesForFeature = async (featureId) => {
    const testCasesResponse = await get_all_test_cases({
      test_plan_id: featureId,
    });
    if (testCasesResponse.success) {
      setTestCasesByFeature((prev) => ({
        ...prev,
        [featureId]: testCasesResponse.features,
      }));
    } else {
      console.error("Failed to fetch test cases:", testCasesResponse.message);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: testCasesResponse.message,
      });
    }
  };

  const handleDeleteTestScript = async (testCase) => {
    // Confirm deletion with the user

    setOpen(true); // Show loading indicator
    try {
      const data = {
        tc_id: testCase.tc_id,
      };
      const response = await Delete_Testscript(data);

      if (response.success) {
        // Fetch updated test cases for the feature
        const testCasesResponse = await get_all_test_cases({
          test_plan_id: testCase.test_plan_id,
        });

        if (testCasesResponse.success) {
          setTestCasesByFeature((prev) => ({
            ...prev,
            [testCase.test_plan_id]: testCasesResponse.features,
          }));

          // Update openTestScripts state to close the deleted test script
          setOpenTestScripts((prev) => {
            const newOpenTestScripts = { ...prev };
            delete newOpenTestScripts[testCase.Test_Case_ID];
            return newOpenTestScripts;
          });

          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Test script deleted successfully",
          });
        } else {
          console.error(
            "Failed to fetch test cases:",
            testCasesResponse.message
          );
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to fetch updated test cases",
          });
          return;
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to delete test script",
        });
      }
    } catch (error) {
      console.error("Error deleting test script:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while deleting the test script",
      });
    } finally {
      setOpen(false);
    }
  };

  const handleGenerateTestScriptClick = async () => {
    // setOpen(true); // Show loading indicator
    setOpenDialog(false);
    try {
      // Call API to generate test script
      setTestScriptLoading((prev) => ({
        ...prev,
        [testcasessss.tc_id]: true, // Mark this test case as loading
      }));
      const response = await generate_test_script({
        tc_id: testcasessss.tc_id,
        project_id: projectData.project_id,
        test_plan_id: testcasessss.test_plan_id,
        url, // Additional field
        automation_tool: automationTool, // Additional field
        language, // Additional field
      });

      if (response.success) {
        // Fetch updated test cases for the feature
        const testCasesResponse = await get_all_test_cases({
          test_plan_id: testcasessss.test_plan_id,
        });

        if (testCasesResponse.success) {
          const updatedTestCases = testCasesResponse.features;
          setTestCasesByFeature((prev) => ({
            ...prev,
            [testcasessss.test_plan_id]: updatedTestCases,
          }));

          // Update openTestScripts to show the newly generated script
          setOpenTestScripts((prev) => ({
            ...prev,
            [testcasessss.Test_Case_ID]: true,
          }));
        } else {
          console.error(
            "Failed to fetch test cases:",
            testCasesResponse.message
          );
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to fetch updated test cases",
          });
          return;
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to generate test script",
        });
        return;
      }
    } catch (error) {
      console.error("Error generating test script:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while generating the test script",
      });
    } finally {
      setTestScriptLoading((prev) => ({
        ...prev,
        [testcasessss.tc_id]: false, // Stop loading for this test case
      }));
      // setOpen(false);
    }
  };

  const [testScriptLoading, setTestScriptLoading] = useState(() => {
    const savedLoadingState = localStorage.getItem('testScriptLoading');
    return savedLoadingState ? JSON.parse(savedLoadingState) : {};
  });

  useEffect(() => {
    localStorage.setItem('testScriptLoading', JSON.stringify(testScriptLoading));
  }, [testScriptLoading]);


  useEffect(() => {
    const fetchData = async () => {
      setOpen(true);
      try {
        const featuresResult = await get_all_test_plans({
          project_id: projectData.project_id,
        });
        if (featuresResult.success) {
          setFeatures(featuresResult.data); // Store feature data
          // Automatically load test cases for features where they have already been generated
          featuresResult.data.forEach((feature) => {
            if (feature.is_test_case_generated) {
              (async () => {
                const testCasesResponse = await get_all_test_cases({
                  test_plan_id: feature.test_plan_id,
                });
                if (testCasesResponse.success) {
                  setTestCasesByFeature((prev) => ({
                    ...prev,
                    [feature.test_plan_id]: testCasesResponse.features,
                  }));
                }
              })();
            }
          });
        } else {
          console.error("Failed to fetch features:", featuresResult.message);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: featuresResult.message,
          });
        }
      } catch (error) {
        console.error("Error fetching features:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error,
        });
      } finally {
        setOpen(false);
      }
    };

    fetchData();
  }, [projectData.project_id]);



  const formatTestData = (testData) => {
    if (typeof testData === "string") {
      // If it's already a string, return it as is
      return testData;
    } else if (typeof testData === "object" && testData !== null) {
      // If it's an object, convert it to a string
      return Object.entries(testData)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n");
    } else {
      // If it's neither a string nor an object, return an empty string
      return "";
    }
  };

  const parseTestData = (testDataString) => {
    // Convert the string back to an object
    const lines = testDataString.split("\n");
    return Object.fromEntries(
      lines.map((line) => {
        const [key, ...valueParts] = line.split(":");
        return [key.trim(), valueParts.join(":").trim()];
      })
    );
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Process 'Preconditions' and 'Test Steps' into arrays
    const testStepsArray = testSteps.split("\n").map((item) => item.trim());
    const testDataArray = testData.split("\n").map((item) => item.trim());

    // Manually format arrays into strings with single quotes
    const testStepsString = `[${testStepsArray.join("', '")}]`;
    const testDataString = `[${testDataArray.join("', '")}]`;

    // Process 'Test Data' into an object
    let testDataObject = {};
    testData.split("\n").forEach((line) => {
      const [key, value] = line.split(":").map((item) => item.trim());
      if (key && value) {
        testDataObject[key] = value;
      }
    });

    // Construct the data object
    const data = {
      test_plan_id: currentFeatureId,
      Test_Case_ID: testCaseId,
      Test_Case_Title: testCaseTitle,
      Preconditions: preconditions, // Assuming this can stay as a string
      Test_Steps: testStepsString, // Stringified array
      Test_Data: testDataString, // Stringified array
      Expected_result: expectedResult,
    };

    try {
      setOpen(true);
      const response = await Create_test_case(data);
      if (response.success) {
        // Refresh test cases after successful creation
        await fetchTestCasesForFeature(currentFeatureId);

        // Reset form fields
        setTestCaseId("");
        setTestCaseTitle("");
        setPreconditions("");
        setTestSteps("");
        setTestData("");
        setExpectedResult("");

        // Close the modal
        handleClosenModal();

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Test case created successfully",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to create test case",
        });
      }
    } catch (error) {
      console.error("Error creating test case:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while creating the test case",
      });
    } finally {
      setOpen(false);
    }
  };
  const [selectedTestCase, setSelectedTestCase] = useState(null);
  // Assuming you have a state for managing folder open/close
  const [isOpenTestCase, setIsOpenTestCase] = useState({});
  const [script, setscript] = useState(false);

  const showscript = (testCase, story) => {
    setscript(true);
    setSelectedTestCase(
      selectedTestCase?.tc_id === testCase.tc_id ? testCase : testCase
    );
    setSelectedStory(story);
    setViewPlan(false);
    setStretegy(false);
    setSelectedStoryvar(false);
  };

  const handleTestCaseClick = (testCase, story) => {
    setSelectedTestCase(
      selectedTestCase?.tc_id === testCase.tc_id ? testCase : testCase
    );
    setSelectedStory(story);
    setViewPlan(false);
    setStretegy(false);
    setscript(false);
    setSelectedStoryvar(false);
  };
  const [selectedOption, setSelectedOption] = useState("test_strategy");
  const toggleTestCase = (testCaseId) => {
    setIsOpenTestCase((prevState) => ({
      ...prevState,
      [testCaseId]: !prevState[testCaseId], // Toggle the specific test case
    }));
  };
  const handleSelection = (option) => {
    setSelectedOption(option);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [url, setUrl] = useState(""); // State for URL input
  const [automationTool, setAutomationTool] = useState("Selenium"); // State for automation tool dropdown
  const [language, setLanguage] = useState("python");
  const [testcasessss, settestcasessss] = useState(null);


  const [previousTestPlanId, setPreviousTestPlanId] = useState(null);
  const [testCaseType, setTestCaseType] = useState(null); // Store fetched test case type
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenremove, setModalIsOpenremove] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const handleTagSelection = (e, tag) => {
    if (e.target.checked) {
      // Add tag to the selectedTags array
      setSelectedTags((prevSelected) => [...prevSelected, tag]);
    } else {
      // Remove tag from the selectedTags array
      setSelectedTags((prevSelected) =>
        prevSelected.filter((selectedTag) => selectedTag !== tag)
      );
    }
  };

  {/* Function to handle tag removal submission */ }
  const handleRemoveTags = async (e) => {
    e.preventDefault();

    if (selectedTags.length === 0) {
      alert('Please select at least one tag to remove.');
      return;
    }

    // Data to send to API
    const data = {
      tc_id: selectedTestCase.tc_id, // Test case ID
      tags: selectedTags, // Tags to remove
    };

    console.log('Removing tags:', data);

    // Call API to remove tags
    const response = await removeTestTags(data); // Replace with the correct API function

    if (response.success) {
      loadTestCaseType(); // Reload or refresh the test case type tags after removal
    } else {
      console.error('Failed to remove test tags', response);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: response,
      });
    }

    // Reset the state and close the modal
    setSelectedTags([]); // Clear the selected tags
    setModalIsOpenremove(false); // Close the modal
  };
  useEffect(() => {
    const loadTestCaseTypes = async () => {
      if (!selectedStory?.test_plan_id || !testCasesByFeature[selectedStory.test_plan_id]) {
        return;
      }

      const updatedTestCases = await Promise.all(
        testCasesByFeature[selectedStory.test_plan_id].map(async (testCase) => {
          const data = { tc_id: testCase.tc_id };
          const testCaseType = await fetchTestCaseType(data);
          return { ...testCase, testCaseType };
        })
      );

      setTestCasesByFeature((prev) => ({
        ...prev,
        [selectedStory.test_plan_id]: updatedTestCases,
      }));
    };

    if (selectedStory?.test_plan_id && selectedStory.test_plan_id !== previousTestPlanId) {
      loadTestCaseTypes();
      setPreviousTestPlanId(selectedStory.test_plan_id);
    }
  }, [selectedStory, testCasesByFeature]);

  // Effect to load test case type when a specific test case is selected
  useEffect(() => {


    loadTestCaseType();
  }, [selectedTestCase]);

  const loadTestCaseType = async () => {
    if (!selectedTestCase) return;

    const data = { tc_id: selectedTestCase.tc_id };
    const response = await fetchTestCaseType(data);

    // Check if the response is successful and extract testing tags
    if (response.success) {
      const testingTags = response.testing_tags.map(tag => tag.testing_tag); // Extract testing_tag names
      setTestCaseType({
        ...response, // Include other properties from the response if needed
        testing_tags: testingTags, // Set extracted testing_tag names
      });
    } else {
      console.error("Failed to fetch test case type:", response);

      // Handle error response if necessary
      setTestCaseType(null); // or however you want to handle failure
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: response,
      });
    }
  };
  const handleOpenDialog = (testcasedata) => {
    setOpenDialog(true);
    settestcasessss(testcasedata);
  };

  // Function to handle modal close
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleRemoveTag = (index) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index)); // Remove the tag at the specified index
  };


  const handleAddTag = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      setTags((prevTags) => [...prevTags, inputValue.trim()]);
      setInputValue(''); // Clear the input field after adding a tag
      event.preventDefault(); // Prevent the form from submitting when Enter is pressed
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Validate if tags are present before submitting the form
    if (tags.length === 0 && inputValue.trim() === '') {
      alert('Please add at least one tag before submitting.');
      return;
    }

    if (!selectedTestCase) return; // Check if selectedTestCase is defined

    setOpen(true); // Set loading state or similar

    // Prepare data to be sent
    const data = {
      tc_id: selectedTestCase.tc_id, // Use the appropriate test case ID
      tags: tags,
    };

    console.log('Data being sent:', data); // Log the data to check its structure

    // Call the API to add the tags
    const response = await addtesttags(data); // Ensure this function is correctly defined

    // Handle the API response
    if (response.success) {
      loadTestCaseType(); // Replace with your success handling function
    } else {
      console.error('Failed to add test tags', response); // Log the response for debugging
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: response,
      });
    }

    // Reset tags and close modal
    setTags([]); // Clear the tags
    setModalIsOpen(false); // Close the modal
    setOpen(false); // Reset loading state
  };
  const [showchatbar, setshowchatbar] = useState(false);
  const [query, setQuery] = useState("");
  const [fullChat, setFullChat] = useState([]);
 
  const sendQuery = async () => {
    if (!query.trim()) return; // Don't send empty queries
  
    // Add user's query to chat
    const newChat = [...fullChat, { type: 'user', content: query }];
    setFullChat(newChat);
    setQuery(''); // Clear the input field
    // setOpen(true);
  
    // Add loading spinner to the chat
    setFullChat((prevChat) => [...prevChat, { type: 'ai', content: 'loading' }]);
  
    try {
      const body = {
        project_id: projectData.project_id,
        question: query,
      };
  
      // Example API call to send query and get response
      const response = await Querychatbot(body);
  
      if (response.success) {
        const aiResponse = response.answer;
  
        // Replace the 'loading' content with the actual response
        setFullChat((prevChat) => {
          const updatedChat = [...prevChat];
          updatedChat[updatedChat.length - 1] = {
            type: 'ai',
            content: typeof aiResponse === 'object' && !Array.isArray(aiResponse) ? 'table' : aiResponse,
            ...(typeof aiResponse === 'object' && { data: aiResponse }),
          };
          return updatedChat;
        });
      } else {
        console.error('Error getting AI response:', response.error || 'Unknown error');
      }
  
    } catch (error) {
      console.error('Error getting AI response:', error);
    }
    // setOpen(false);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      setOpen(true);
      try {
        const response = await Querychatbothistory({
          project_id: projectData.project_id,
        });
        if (response.success) {
          const newChat = [...fullChat];
          if (response.chat_history && Array.isArray(response.chat_history)) {
            // Iterate over each chat history entry
            response.chat_history.forEach(chat => {
              // Add the user's question to the chat
              newChat.push({ type: 'user', content: chat.question });

              // Check if the answer contains an object with arrays
              const aiResponse = chat.answer;
              if (typeof aiResponse === 'object' && !Array.isArray(aiResponse)) {
                // Pass the dynamic table data to the chat
                newChat.push({ type: 'ai', content: 'table', data: aiResponse });
              } else {
                // Add AI's response to chat as normal text
                newChat.push({ type: 'ai', content: aiResponse });
              }
            });
          }

          // Update fullChat with new chat messages
          setFullChat(newChat);
        } else {
          console.error("Failed to fetch features:", response.message);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.message,
          });
        }
      } catch (error) {
        console.error("Error fetching features:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error,
        });
      } finally {
        setOpen(false);
      }
    };

    fetchData();
  }, [projectData.project_id]);

  // UseEffect to restore spinner state when returning to the page
  useEffect(() => {
    const isGeneratingFromStorage = localStorage.getItem(`isGenerating_${projectData.project_id}`);
    if (isGeneratingFromStorage === "true") {
      setIsGenerating(true); // Restore spinner state if it was still generating before leaving the page
    } else {
      setIsGenerating(false); // No API call in progress
    }
  }, [projectData.project_id]);


  return (
    <div>
      <Charging open={open} />
      <Toast ref={toast} />
      <div className={styles.container}>

        <div className={styles.sidebar}>
          <div className={styles.folder}>
            <div
              className={styles.folderHeader}
              onClick={() => toggleFolder("project")}
            >
              <div
                className={`${styles.arrowIcon} ${isOpenfol.project ? styles.rotate180deg : styles.rotate90deg
                  }`}
              >
                <img src={downArrow} alt="Arrow" />
              </div>
              <h4>{applicationname}</h4>
            </div>
            {isOpenfol.project && (
              <div className={styles.subFolder}>
                <div className={styles.folder}>
                  <div
                    onClick={() => {
                      stretegyClick();
                      handleSelection("test_strategy");
                    }}
                    className={`${styles.folderHeader} ${selectedOption === "test_strategy" ? styles.highlight : ""
                      }`}
                  >
                    <div
                      className={`${styles.arrowIcon} ${isOpenfol.test_strategy
                        ? styles.rotate180deg
                        : styles.rotate90deg
                        }`}
                    >
                      <img src={downArrow} alt="Arrow" />
                    </div>
                    <p style={{ display: "flex", alignItems: "center" }}>
                      Test Strategy
                      {!isGenerated && (
                        isGenerating ? (
                          <NewLoading widtht="100px" heightt="25px" style={{ marginLeft: "10px" }} />
                        ) : ""
                      )}
                    </p>
                  </div>
                  {isGenerated && (
                    <>
                      <div
                        className={`${styles.folderHeader} ${selectedOption === "test_plan" ? styles.highlight : ""
                          }`}
                        onClick={() => {
                          handleViewTestPlan();
                          handleSelection("test_plan");
                        }}
                      >
                        <div
                          onClick={() => toggleFolder("folder1")}
                          className={`${styles.arrowIcon} ${isOpenfol.folder1
                            ? styles.rotate180deg
                            : styles.rotate90deg
                            }`}
                        >
                          <img src={downArrow} alt="Arrow" />
                        </div>
                        <p>Test Plan</p>
                      </div>

                      {isOpenfol.folder1 && (
                        <div className={styles.subFolder}>
                          {storydata.map((testPlan, index) => (
                            <div
                              className={styles.folder}
                              key={testPlan.test_plan_id}
                            >
                              <div
                                className={`${styles.folderHeadernew} ${selectedOption ===
                                  `user_story_${testPlan.jira_story.Story_ID}`
                                  ? styles.highlight
                                  : ""
                                  }`}
                                onClick={() => {
                                  handleStoryClick(testPlan);
                                  handleSelection(
                                    `user_story_${testPlan.jira_story.Story_ID}`
                                  );
                                }}
                              >
                                <div
                                  className={`${styles.arrowIcon} ${isOpenfol[`folder${index + 2}`]
                                    ? styles.rotate180deg
                                    : styles.rotate90deg
                                    }`}
                                  onClick={() =>
                                    toggleFolder(`folder${index + 2}`)
                                  }
                                >
                                  <img src={downArrow} alt="Arrow" />
                                </div>
                                {/* <p>{`User Story ${testPlan.jira_story.Story_ID}`}</p> */}
                                <p style={{ display: "flex", alignItems: "center" }}>
                                  {`User Story ${testPlan.jira_story.Story_ID}`}
                                  {(!testPlan.is_test_case_generated &&
                                    !testCasesGenerated[testPlan.test_plan_id] &&
                                    testCasesLoading[testPlan.test_plan_id]) ? (
                                    <NewLoading widtht="100px" heightt="25px" style={{ marginLeft: "10px" }} />
                                  ) : ""}
                                </p>
                              </div>

                              {isOpenfol[`folder${index + 2}`] && (
                                <div className={styles.subFolder}>
                                  {testCasesByFeature[testPlan.test_plan_id]
                                    ?.length &&
                                    testCasesByFeature[
                                      testPlan.test_plan_id
                                    ].map((testCase) => (
                                      <div
                                        className={styles.folder}
                                        key={testCase.tc_id}
                                      >
                                        <div
                                          className={`${styles.folderHeadernew
                                            } ${selectedOption ===
                                              `test_case_${testCase.tc_id}`
                                              ? styles.highlight
                                              : ""
                                            }`}
                                          onClick={() => {
                                            handleTestCaseClick(
                                              testCase,
                                              testPlan
                                            );
                                            handleSelection(
                                              `test_case_${testCase.tc_id}`
                                            );
                                          }}
                                        >
                                          <div
                                            className={`${styles.arrowIcon} ${isOpenTestCase[testCase.tc_id]
                                              ? styles.rotate180deg
                                              : styles.rotate90deg
                                              }`}
                                            onClick={() =>
                                              toggleTestCase(testCase.tc_id)
                                            }
                                          >
                                            <img src={downArrow} alt="Arrow" />
                                          </div>
                                          <p style={{ display: "flex", alignItems: "center" }}>
                                            {testCase.Test_Case_ID}
                                            {!testCase.is_test_script_generated && testScriptLoading[testCase.tc_id] && (
                                              <NewLoading widtht="100px" heightt="30px" style={{ marginLeft: "10px" }} />
                                            )}
                                          </p>
                                        </div>

                                        {isOpenTestCase[testCase.tc_id] && (
                                          <div className={styles.subFolder}>
                                            {testCase.is_test_script_generated && (
                                              <div
                                                className={`${styles.folderHeadernew
                                                  } ${selectedOption ===
                                                    `test_script_${testCase.tc_id}`
                                                    ? styles.highlight
                                                    : ""
                                                  }`}
                                                onClick={() => {
                                                  showscript(
                                                    testCase,
                                                    testPlan
                                                  );
                                                  handleSelection(
                                                    `test_script_${testCase.tc_id}`
                                                  );
                                                }}
                                              >
                                                <p className={styles.gapfor}>
                                                  Test Script
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {!showchatbar && <div className={styles.fixedAskAI} onClick={() => setshowchatbar(true)}>
          <img src={image} alt="AI Icon" />
          <span className={styles.askText}>Ask AI</span>

        </div>}
        <div className={!showchatbar ? styles.mainContent : styles.mainContent60}>
          {stretegy && (
            <div>
              <div className={styles.main}>
                <div className={styles.test}>
                  <h5>Requirements Document</h5>
                </div>
                <div className={styles.prd}>
                  <div className={styles.test}>
                    <h5>Test Strategy</h5>
                  </div>

                  {!isGenerated && (
                    isGenerating ? (
                      // <div className={styles.spinnerContainer}>
                      //   <div className={styles.spinner}></div>
                      // </div>
                      <NewLoading widtht="100px" heightt="35px" />
                    ) : (
                      <button className={styles.commonbtn} onClick={handleOpenModal}>
                        Generate Test Plans
                      </button>
                    )
                  )}
                  <img
                    className={styles.down}
                    src={download}
                    onClick={() => handleDownload()}
                  />
                </div>
                {isModalOpen && (
                  <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                      <h5>Enter Jira Details</h5>{" "}
                      <form
                        className={styles.formm}
                        onSubmit={handleFormSubmit}
                      >
                        {" "}
                        <div className={styles.formGroup}>
                          {" "}
                          <label>Jira Server</label>{" "}
                          <input
                            type="text"
                            name="jiraServer"
                            value={formValues.jiraServer}
                            onChange={handleInputChange}
                            required
                          />{" "}
                        </div>{" "}
                        <div className={styles.formGroup}>
                          {" "}
                          <label>Jira Email</label>{" "}
                          <input
                            type="email"
                            name="jiraEmail"
                            value={formValues.jiraEmail}
                            onChange={handleInputChange}
                            required
                          />{" "}
                        </div>{" "}
                        <div className={styles.formGroup}>
                          {" "}
                          <label>Jira API Token</label>{" "}
                          <input
                            type="text"
                            name="jiraApiToken"
                            value={formValues.jiraApiToken}
                            onChange={handleInputChange}
                            required
                          />{" "}
                        </div>{" "}
                        <div className={styles.formGroup}>
                          {" "}
                          <label>Jira Project Key</label>{" "}
                          <input
                            type="text"
                            name="jiraProjectKey"
                            value={formValues.jiraProjectKey}
                            onChange={handleInputChange}
                            required
                          />{" "}
                        </div>{" "}
                        <div className={styles.formActions}>
                          {" "}
                          <button
                            type="submit"
                            className={styles.submitBtn}
                            onClick={handleFormSubmit}
                          >
                            {" "}
                            Submit{" "}
                          </button>{" "}
                          <button
                            type="button"
                            onClick={handleCloseModal}
                            className={styles.closeBtn}
                          >
                            {" "}
                            Close{" "}
                          </button>{" "}
                        </div>{" "}
                      </form>
                    </div>
                  </div>
                )}
              </div>
              {responseData.map((response, idx) => {
                return (
                  <div key={idx} className={styles.main2}>
                    <p
                      className={styles.main2p}
                      dangerouslySetInnerHTML={{
                        __html: response?.prd.replace(/\n/g, "<br/>"),
                      }}
                    />
                    <p className={styles.testPlan}>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {response?.test_strategy}
                      </ReactMarkdown>
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          {!stretegy && (
            // <ProjectDetails
            //   projectData={projectData}
            //   project_id={projectData?.project_id}
            // />
            <div className={stylesD.fixheight}>
              {/* <div className={stylesD.project}>
          <h5>Project Details</h5>
        </div> */}
              {viewPlan && (
                <>
                  <div className={stylesD.application}>
                    <h5>Application Name: {projectData?.application_name}</h5>
                    {/* <h5>Url of the application: {projectData?.url}</h5> */}
                    {/* <h5>
            3 Test plan Document:
            {projectData?.test_plan_document || "No document uploaded"}
          </h5> */}
                  </div>

                  <div className={stylesD.feature}>Test Plan</div>
                  <div className={stylesD.testPlan}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {Testplan}
                    </ReactMarkdown>
                  </div>
                </>
              )}
              {selectedStory && (
                <div>
                  {selectedStoryvar && (
                    <>
                      <div className={stylesD.feature}>
                        <h5>Jira User Story</h5>
                      </div>
                      <div className={stylesD.insidefeature}>
                        <h5>
                          {/* {index + 1}. Test Plan ID: {feature.test_plan_id} */}
                          <br />
                          User Story: {selectedStory.jira_story?.Summary}
                          <br />
                          Story ID: {selectedStory.jira_story?.Story_ID}
                          <br />
                          Status: {selectedStory.jira_story?.Status}
                          <br />
                          Assignee: {selectedStory.jira_story?.Assignee}
                          <br />
                          <span style={{ whiteSpace: "pre-line" }}>
                            {selectedStory.jira_story?.Description}
                          </span>
                        </h5>

                        <div className={stylesD.butto}>
                          {(!selectedStory.is_test_case_generated &&
                            !testCasesGenerated[selectedStory.test_plan_id]) ? (
                            testCasesLoading[selectedStory.test_plan_id] ? (
                              // <div className={styles.spinnerContainer}>
                              //   <div className={styles.spinner}></div> {/* Spinner while loading */}
                              // </div>
                              <NewLoading widtht="100px" heightt="50px" />
                            ) : (
                              <button
                                className={stylesD.gen}
                                onClick={() =>
                                  handleGenerateTestCases(
                                    projectData?.project_id,
                                    selectedStory.test_plan_id,
                                    selectedStory.jira_story?.Story_ID
                                  )
                                }
                              >
                                Generate Testcases
                              </button>
                            )
                          ) : (
                            <button
                              className={stylesD.add}
                              onClick={() =>
                                handleAddTestCaseClick(selectedStory.test_plan_id)
                              }
                            >
                              Add New Test Case
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className={stylesD.insidefeature}>
                    {selectedTestCase &&
                      testCasesByFeature[selectedStory.test_plan_id] && (
                        <div>
                          {selectedTestCase &&
                            testCasesByFeature[selectedStory.test_plan_id]
                              .filter(
                                (testCase) =>
                                  selectedTestCase?.tc_id === testCase.tc_id
                              )
                              .map((testCase) => {
                                const idx = testCasesByFeature[selectedStory.test_plan_id].findIndex(
                                  (t) => t.tc_id === testCase.tc_id
                                );
                              return(
                                <div
                                  key={idx}
                                  className={stylesD.testcasefeature}
                                >
                                  {!script ? (
                                    <>
                                      <div className={stylesD.testcase}>
                                        <h5>
                                          Test Cases for{" "}
                                          {selectedStory.jira_story?.Summary}
                                        </h5>
                                        <div className={stylesD.addarrow}>

                                          {/* <img
                              src={arrow}
                              className={`${stylesD.arrow} ${
                                expandedFeatures[selectedStory.test_plan_id]
                                  ? stylesD.expanded
                                  : ""
                              }`}
                              onClick={() => {
                                setExpandedFeatures((prev) => ({
                                  ...prev,
                                  [selectedStory.test_plan_id]:
                                    !prev[selectedStory.test_plan_id],
                                }));
                              }}
                            /> */}
                                        </div>
                                      </div>
                                      <div>
                                        <label>Test Case ID:</label>
                                        <input
                                          type="text"
                                          value={testCase.Test_Case_ID}
                                          className={stylesD.same}
                                          onChange={(e) =>
                                            handleTestCaseChange(
                                              e,
                                              selectedStory.test_plan_id,
                                              idx,
                                              "Test_Case_ID"
                                            )
                                          }
                                          readOnly={true
                                            // editingTestCase?.Test_Case_ID !==
                                            // testCase.Test_Case_ID
                                          }
                                        />
                                      </div>
                                      <div>
                                        <label>Test Case Types:</label>
                                        {testCaseType && testCaseType.testing_tags.length > 0 ? (
                                          <div>
                                            <p className={styles.grayText}>
                                              {testCaseType.testing_tags.join(', ')} {/* Join the tags with a comma */}
                                              <button className={styles.addButton} onClick={() => setModalIsOpen(true)}> + </button>
                                              <button className={styles.addButton} onClick={() => setModalIsOpenremove(true)}> - </button>
                                            </p>
                                          </div>
                                        ) : (
                                          <div>
                                            <p className={styles.grayText}>No test case type selected or data unavailable.</p>
                                            <button className={styles.addButton} onClick={() => setModalIsOpen(true)}> + </button>
                                          </div>
                                        )}


                                        {modalIsOpenremove && (
                                          <div className={styles.modalOverlay}>
                                            <div className={styles.modalContent}>
                                              <h5>Select Tags to Remove</h5>
                                              <form className={styles.formm} onSubmit={handleRemoveTags}>
                                                <div className={styles.formGroup}>
                                                  <label>Available Tags:</label>
                                                  <div>
                                                    {/* Render the test case type tags as a list with checkboxes */}
                                                    {testCaseType.testing_tags.map((tag, index) => (
                                                      <div key={index} className={styles.tagItem}>
                                                        <input
                                                          type="checkbox"
                                                          id={`tag-${index}`}
                                                          value={tag}
                                                          onChange={(e) => handleTagSelection(e, tag)} // Handle tag selection
                                                        />
                                                        <label htmlFor={`tag-${index}`}>{tag}</label>
                                                      </div>
                                                    ))}
                                                  </div>
                                                </div>

                                                <div className={styles.formActions}>
                                                  <button type="submit" className={styles.submitBtn}>
                                                    Remove Selected Tags
                                                  </button>
                                                  <button
                                                    type="button"
                                                    onClick={() => setModalIsOpenremove(false)} // Close the modal
                                                    className={styles.closeBtn}
                                                  >
                                                    Close
                                                  </button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        )}



                                        {/* Modal for adding tags */}
                                        {modalIsOpen && (
                                          <div className={styles.modalOverlay}>
                                            <div className={styles.modalContent}>
                                              <h5>Add new Tags for test case</h5>
                                              <form className={styles.formm} onSubmit={handleSubmit}>
                                                <div className={styles.formGroup}>
                                                  <label htmlFor="tags">Tags</label>
                                                  <input
                                                    type="text"
                                                    name="tags"
                                                    value={inputValue}
                                                    onChange={(e) => setInputValue(e.target.value)}
                                                    onKeyDown={handleAddTag}
                                                    placeholder="Press Enter to add tag"
                                                  />
                                                </div>

                                                <div className={styles.formGroup}>
                                                  <label>Current Tags:</label>
                                                  <div>
                                                    {tags.map((tag, index) => (
                                                      <span key={index} className={styles.tag}>
                                                        {tag}
                                                        <span
                                                          className={styles.closeIcon}
                                                          onClick={() => handleRemoveTag(index)} // Function to remove tag
                                                        >
                                                          &times; {/* Cross icon (can use an icon library or SVG if desired) */}
                                                        </span>
                                                      </span>
                                                    ))}
                                                  </div>
                                                </div>

                                                <div className={styles.formActions}>
                                                  <button type="submit" className={styles.submitBtn}>
                                                    Submit
                                                  </button>
                                                  <button
                                                    type="button"
                                                    onClick={() => setModalIsOpen(false)}
                                                    className={styles.closeBtn}
                                                  >
                                                    Close
                                                  </button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        )}

                                      </div>




                                      <div>
                                        <label>Test Case Title:</label>
                                        <input
                                          type="text"
                                          value={testCase.Test_Case_Title}
                                          className={stylesD.same}
                                          onChange={(e) =>
                                            handleTestCaseChange(
                                              e,
                                              selectedStory.test_plan_id,
                                              idx,
                                              "Test_Case_Title"
                                            )
                                          }
                                          readOnly={
                                            editingTestCase?.Test_Case_ID !==
                                            testCase.Test_Case_ID
                                          }
                                        />
                                      </div>
                                      <div>
                                        <label>Preconditions:</label>
                                        <textarea
                                          value={testCase.Preconditions}
                                          className={stylesD.editableTextArea}
                                          onChange={(e) =>
                                            handleTestCaseChange(
                                              e,
                                              selectedStory.test_plan_id,
                                              idx,
                                              "Preconditions"
                                            )
                                          }
                                          readOnly={
                                            editingTestCase?.Test_Case_ID !==
                                            testCase.Test_Case_ID
                                          }
                                        />
                                      </div>
                                      <div>
                                        <label>Test Steps:</label>
                                        <textarea
                                          value={
                                            Array.isArray(testCase.Test_Steps)
                                              ? testCase.Test_Steps.join("\n")
                                              : testCase.Test_Steps
                                          }
                                          className={stylesD.editableTextArea}
                                          onChange={(e) =>
                                            handleTestCaseChange(
                                              e,
                                              selectedStory.test_plan_id,
                                              idx,
                                              "Test_Steps"
                                            )
                                          }
                                          readOnly={
                                            editingTestCase?.Test_Case_ID !==
                                            testCase.Test_Case_ID
                                          }
                                        />
                                      </div>
                                      <div>
                                        <label>Test Data:</label>
                                        <textarea
                                          value={formatTestData(
                                            testCase.Test_Data
                                          )}
                                          className={stylesD.editableTextArea}
                                          onChange={(e) =>
                                            handleTestCaseChange(
                                              e,
                                              selectedStory.test_plan_id,
                                              idx,
                                              "Test_Data"
                                            )
                                          }
                                          readOnly={
                                            editingTestCase?.Test_Case_ID !==
                                            testCase.Test_Case_ID
                                          }
                                        />
                                      </div>
                                      <div>
                                        <label>Expected Result:</label>
                                        <textarea
                                          value={testCase.Expected_result}
                                          className={stylesD.editableTextArea}
                                          onChange={(e) =>
                                            handleTestCaseChange(
                                              e,
                                              selectedStory.test_plan_id,
                                              idx,
                                              "Expected_result"
                                            )
                                          }
                                          readOnly={
                                            editingTestCase?.Test_Case_ID !==
                                            testCase.Test_Case_ID
                                          }
                                        />
                                      </div>
                                      <div className={stylesD.space}>
                                        <div className={stylesD.threebutto}>
                                          {editingTestCase?.Test_Case_ID ===
                                            testCase.Test_Case_ID ? (
                                            <>
                                              <button
                                                className={stylesD.save}
                                                onClick={() =>
                                                  handleSaveTestCase(
                                                    selectedStory.test_plan_id,
                                                    idx
                                                  )
                                                }
                                              >
                                                Save
                                              </button>
                                              <button
                                                className={stylesD.cancel}
                                                onClick={() =>
                                                  setEditingTestCase(null)
                                                }
                                              >
                                                Cancel
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                className={stylesD.edit}
                                                onClick={() =>
                                                  handleEditClick(testCase)
                                                }
                                              >
                                                Edit
                                              </button>
                                              <button
                                                className={stylesD.delete}
                                                onClick={() =>
                                                  handleDeleteTestCase(testCase)
                                                }
                                              >
                                                Delete
                                              </button>
                                            </>
                                          )}
                                        </div>
                                        <div>
                                          {!testCase.is_test_script_generated && testScriptLoading[testCase.tc_id] && (
                                            <NewLoading widtht="100px" heightt="30px" /> // Spinner outside the button
                                          )}

                                          {!testCase.is_test_script_generated && !testScriptLoading[testCase.tc_id] && (
                                            <button
                                              className={stylesD.gen}
                                              onClick={() => {
                                                if (testCase.is_test_script_generated) {
                                                  setOpenTestScripts((prev) => ({
                                                    ...prev,
                                                    [testCase.Test_Case_ID]: !prev[testCase.Test_Case_ID],
                                                  }));
                                                } else {
                                                  handleOpenDialog(testCase);
                                                }
                                              }}
                                            >
                                              {testCase.is_test_script_generated ? (
                                                openTestScripts[testCase.Test_Case_ID] ? "Hide Test Script" : "Show Test Script"
                                              ) : (
                                                "Generate Test Script"
                                              )}
                                            </button>
                                          )}
                                        </div>


                                      </div>
                                    </>
                                  ) : (
                                    <div>
                                      <div className={stylesD.testscript1}>
                                        <div className={stylesD.flexContainer}>
                                          <h5 className={stylesD.marginkro}>
                                            Test Script for{" "}
                                            {testCase.Test_Case_Title}
                                          </h5>
                                          {outputs[testCase.Test_Case_ID] && <p className={stylesD.marginkro}>
                                            Output
                                          </p>}
                                        </div>
                                      </div>

                                      <div className={stylesD.insidefeaturenew}>
                                        <div
                                          className={stylesD.flexContainernew}
                                        >
                                          {/* First column - 50% width */}
                                          <div className={outputs[testCase.Test_Case_ID] ? stylesD.leftColumn : stylesD.leftColumnnew}>
                                            {editingTestScriptId ===
                                              testCase.Test_Case_ID ? (
                                              <textarea
                                                className={
                                                  stylesD.testScriptTextarea
                                                }
                                                value={editedTestScriptContent}
                                                onChange={(e) =>
                                                  setEditedTestScriptContent(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ) : (
                                              <pre
                                                className={stylesD.testScript}
                                              >
                                                {testCase.Test_Script}
                                              </pre>
                                            )}
                                          </div>

                                          {/* Second column - 50% width */}
                                          {outputs[testCase.Test_Case_ID] && <div
                                            className={stylesD.rightColumnnr}
                                          >
                                            <pre
                                              className={stylesD.testScript}
                                            >
                                              {outputs[testCase.Test_Case_ID]}
                                            </pre>
                                          </div>}
                                        </div>

                                        <div className={stylesD.space}>
                                          <div className={stylesD.threebutto}>
                                            {editingTestScriptId ===
                                              testCase.Test_Case_ID ? (
                                              // If editing, show Save and Cancel buttons
                                              <>
                                                <button
                                                  className={stylesD.save}
                                                  onClick={() =>
                                                    handleSaveTestScript(
                                                      testCase
                                                    )
                                                  }
                                                >
                                                  Save
                                                </button>
                                                <button
                                                  className={stylesD.cancel}
                                                  onClick={() =>
                                                    setEditingTestScriptId(null)
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </>
                                            ) : (
                                              // If not editing, show Edit and Delete buttons
                                              <>
                                                <button
                                                  className={stylesD.edit}
                                                  onClick={() =>
                                                    handleEditTestScript(
                                                      testCase
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </button>
                                                <button
                                                  className={stylesD.delete}
                                                  onClick={() =>
                                                    handleDeleteTestScript(
                                                      testCase
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </button>
                                              </>
                                            )}
                                          </div>
                                          <div>
                                            <button
                                              className={stylesD.gen}
                                              onClick={() => handleExecute(testCase.Test_Script, testCase.Test_Case_ID)}
                                            >
                                              Execute
                                            </button>
                                            <button
                                              className={stylesD.gen}
                                              onClick={() =>
                                                handleDownloadTestScript(
                                                  testCase
                                                )
                                              }
                                            >
                                              Download
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )})}
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {showchatbar && (
          <div className={styles.sideDrawer}>
            <div className={styles.drawerHeader}>
              <h5>Ask AI</h5>
              <span className={styles.closeIcon2} onClick={() => setshowchatbar(false)}>
                &times; {/* Close icon */}
              </span>
            </div>



<div className={styles.drawerContent}>
  {/* Render chat messages */}
  {fullChat.map((item, idx) => (
    <div key={idx} className={styles.message}>
      {item.type === 'user' ? (
        <div className={styles.userMessage}>{item.content}</div>
      ) : item.content === 'table' ? (
        <div className={styles.tableMessage}>
          {/* Render table dynamically for AI response */}
          <table className={styles.responseTable}>
            <thead>
              <tr>
                {Object.keys(item.data)
                  .filter(header => header !== 'tc_id') // Filter out 'tc_id' from headers
                  .map((header, idx) => (
                    <th key={idx}>{header}</th> // Render dynamic headers
                  ))}
              </tr>
            </thead>
            <tbody>
              {/* Create rows by iterating over the arrays inside the answer */}
              {item.data[Object.keys(item.data)[0]].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.keys(item.data)
                    .filter(key => key !== 'tc_id') // Filter out 'tc_id' from rows
                    .map((key, colIdx) => (
                      <td key={colIdx}>{item.data[key][rowIndex]}</td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles.aiMessage}>
          {item.content === 'loading' ? (
            <NewLoading widtht="100px" heightt="30px" /> // Display spinner while loading
          ) : (
            item.content
          )}
        </div>
      )}
    </div>
  ))}
</div>


            <div className={styles.queryInputContainer}>
              <textarea
                placeholder="Ask your query..."
                className={styles.queryInput}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <button
                className={styles.sendButton}
                onClick={sendQuery}

              // Function to handle sending the query
              // sendQuery(query);
              // Clear the input field after sending

              >
                ➤ {/* Arrow icon, you can replace this with an actual icon from an icon library */}
              </button>
            </div>

          </div>


         

        )}

        {isModalOpenn && (
          <div className={stylesD.modalOverlay}>
            <div className={stylesD.modalContent}>
              <h5>Add New Test Case</h5>
              <div className={stylesD.modelinside}>
                <form onSubmit={handleSave}>
                  <div className={stylesD.formRow}>
                    <div className={stylesD.inputGroup}>
                      <label htmlFor="testCaseId">Test Case ID</label>
                      <input
                        type="text"
                        id="testCaseId"
                        value={testCaseId}
                        className={stylesD.inp}
                        onChange={(e) => setTestCaseId(e.target.value)}
                      />
                    </div>
                    <div className={stylesD.inputGroup}>
                      <label htmlFor="testCaseTitle">Test Case Title</label>
                      <input
                        type="text"
                        id="testCaseTitle"
                        value={testCaseTitle}
                        className={stylesD.inp}
                        onChange={(e) => setTestCaseTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={stylesD.formRow}>
                    <div className={stylesD.inputGroup}>
                      <label htmlFor="preconditions">Preconditions</label>
                      <textarea
                        id="preconditions"
                        value={preconditions}
                        className={stylesD.inp}
                        onChange={(e) => setPreconditions(e.target.value)}
                      />
                    </div>
                    <div className={stylesD.inputGroup}>
                      <label htmlFor="testSteps">Test Steps</label>
                      <textarea
                        id="testSteps"
                        value={testSteps}
                        className={stylesD.inp}
                        onChange={(e) => setTestSteps(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={stylesD.formRow}>
                    <div className={stylesD.inputGroup}>
                      <label htmlFor="testData">Test Data</label>
                      <textarea
                        id="testData"
                        value={testData}
                        className={stylesD.inp}
                        onChange={(e) => setTestData(e.target.value)}
                      />
                    </div>

                    <div className={stylesD.inputGroup}>
                      <label htmlFor="expectedResult">Expected Result</label>
                      <textarea
                        id="expectedResult"
                        value={expectedResult}
                        className={stylesD.inp}
                        onChange={(e) => setExpectedResult(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={stylesD.buttonGroup}>
                    <button className={stylesD.savetest} type="submit">
                      Submit
                    </button>
                    <button
                      className={stylesD.cancel}
                      type="button"
                      onClick={handleClosenModal}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {openDialog &&
          (
            <div className={styles.modalOverlay}>
              <div className={styles.modalContent}>
                <h5>Generate Test Script</h5>
                <form className={styles.formm} onSubmit={handleGenerateTestScriptClick}>
                  <div className={styles.formGroup}>
                    <label htmlFor="url">URL</label>
                    <input
                      type="text"
                      name="url"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)} required
                    />
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="automation_tool">Automation Tool</label>
                    <select
                      name="automation_tool"


                      required
                      id="automationTool"
                      value={automationTool}

                      onChange={(e) => setAutomationTool(e.target.value)}
                      className={styles.dropdown}
                    >
                      {/* <option value="" disabled>Select Automation Tool</option> */}
                      <option value="Selenium">Selenium</option>
                      <option value="Playwright">Playwright</option>
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="language">Language</label>
                    <select
                      name="language"

                      required
                      className={styles.dropdown}
                      id="language"
                      value={language}

                      onChange={(e) => setLanguage(e.target.value)}
                    >
                      {/* <option value="" disabled>Select Language</option> */}
                      <option value="python">Python</option>
                      <option value="java">Java</option>
                    </select>
                  </div>

                  <div className={styles.formActions}>
                    <button type="submit" className={styles.submitBtn}>
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={handleCloseDialog}
                      className={styles.closeBtn}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )
        }

      </div>
    </div >
  );
};

export default TestStrategy;
