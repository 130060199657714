import { configureStore } from "@reduxjs/toolkit";
import flowReducer from "./slice/flowSlice";
import vizardReducer from "./slice/dataVizardSlice";
import loadingOrErrorReducer from "./slice/loadingAndError";

const appStore = configureStore({
    reducer: {
        flowStore: flowReducer,
        vizardStore: vizardReducer,
        loadingErrorStore: loadingOrErrorReducer
    }
})

export default appStore;