import React, { useEffect } from "react";
import mlgenaiLogo from "../../assests/images/header/genaiLogo.png";
import profileIcon from "../../assests/images/header/profileIcon.png";
import settings from "../../assests/images/header/settings.png";
import { Link } from "react-router-dom";
import profile from "../../assests/images/logo/profile.png";
import userIcon from "../../assests/images/logo/userIcon.png";
import logout from "../../assests/images/logo/logout.png";
import TestingAIed from "../../assests/images/testingAIednew.png";
import mlangles from "../../assests/images/mlangles_ai_logo.png";
import styles from "./headercss.module.css";
import { Backdrop } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import Cookies from "universal-cookie";

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();

  const { pathname } = location;

  // useEffect(() => {
  //   checkLogout();
  // }, [pathname]);

  // function checkLogout() {
  //   try {
  //     const checkLogin = cookies.get("genAi");
  //     if (!checkLogin) {
  //       navigate("/");
  //     }

  //     const genAi = JSON.parse(localStorage.getItem("genAi"));
  //     if (!genAi) {
  //       navigate("/");
  //     }
  //   } catch (e) {
  //     console.log("error--", e);
  //   }
  // }

  function logoutFn() {
    localStorage.removeItem("genAi");
    cookies.remove("genAi");
    setOpen(false);
    navigate("/");
  }

  return (
    <>
      <div className={styles.full}>
        {/* <div>
          <img src={mlgenaiLogo} alt="mlgenai-logo" />
        </div> */}
       <div className={styles.container}>
    <div className={styles.logo}>
      <img src={TestingAIed} alt="testingAIed-logo" className={styles.image} />
    </div>
    <h1 className={styles.heading}>TestingAIde by</h1>
    <div className={styles.logo}>
      <img src={mlangles} alt="mlangles-logo" className={styles.image2} />
    </div>
  </div>
        <div className={styles.right}>
          <div className={styles.cursor}>
            <img src={settings} alt="profileIcon" />
          </div>

          <div className={styles.dropdown}>
            <img
              src={profileIcon}
              alt="profileIcon"
              className="btn btn-secondary dropdown-toggle"
              data-bs-toggle="dropdown"
            />

            {/* <ul className="dropdown-menu">
              <li>
                <Link to="/profile">
                  <span className="colorName d-flex align-items-center">
                    <img src={profile} alt="" />
                    Profile Settings
                  </span>
                </Link>
              </li>

              <li>
                <Link to="/userManagement">
                  <span className="colorName d-flex align-items-center">
                    <img src={userIcon} alt="" />
                    User & Role Management
                  </span>
                </Link>
              </li>
              <li onClick={() => setOpen(true)}>
                <span className="colorName d-flex align-items-center">
                  <img src={logout} alt="" />
                  Logout
                </span>
              
              </li>
            </ul> */}
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div className={styles.logout}>
          <h2>Logout</h2>
          <p>Would you like to logout?</p>
          <div className={styles.btnLogout}>
            <button onClick={() => logoutFn()}>Yes</button>
            <button onClick={() => setOpen(false)}>No</button>
          </div>
        </div>
      </Backdrop>
    </>
  );
};

export default Header;
