import { createSlice } from "@reduxjs/toolkit";

const flowslice = createSlice({
    name: "flowSlice",
    initialState: {
        nodes: [],
        edges: [],
        expertSource: {},
        nodeIndex: -1
    },
    reducers: {
        updateNodes: (state, action) => {
            state.nodes = action.payload
        },
        updateEdges: (state, action) => {
            state.edges = action.payload
        },
        expertSourceData: (state, action) => {
            state.expertSource = action.payload;
        },
        updateNodeIndex: (state, action) => {
            state.nodeIndex = action.payload;
        }
    }
})

export const { updateNodes, updateEdges, expertSourceData, updateNodeIndex } = flowslice.actions;
const flowReducer = flowslice.reducer;
export default flowReducer;
