import { Backdrop } from "@mui/material";
import React from "react";
import Loading from "../../src/components/loading/index";
const Charging = ({ open }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      // onClick={handleClose}
    >
      <Loading loading={open} />
      {/* <Loading color="inherit" /> */}
    </Backdrop>
  );
};

export default Charging;
