import React, { useEffect, useState, useRef } from "react";
import styles from "../AutomationTest/ProjectCard.module.css";
import vectorImage from "../assests/images/appstore/upload.png";
import card_icon from "../assests/images/appstore/Card.png";
import options from "../assests/images/appstore/options.png";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { Toast } from "primereact/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Delete_Project,
  Post_test_automation_projects,
  PostTeststrategy,
  test_automation_projects,
} from "../commonApi";
import Charging from "../Charging";
import NewLoading from "../components/loading";

const TestProjects = () => {
  const [projects, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [creationInProgress, setCreationInProgress] = useState(false);

  const toast = useRef(null);
  const [newProject, setNewProject] = useState({
    projectName: "",
    file: null,
  });

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    // Check if the project creation was interrupted (e.g., page reload during creation)
    const ongoingCreation = localStorage.getItem("creationInProgress") === "true";
    if (ongoingCreation) {
      setCreationInProgress(true);
      console.log("true");
    }
    else{
      setIsCreatingProject(false); // Stop showing spinner in all cases
      setCreationInProgress(false);
      console.log("false");
    }
  }, []);

  const navigate = useNavigate();
  const [optionsMenuIndex, setOptionsMenuIndex] = useState(null);

  const handleProjectClick = async (project, event) => {
    event.stopPropagation();
    const data = { project_id: project.project_id };
    const response = await PostTeststrategy(data);
    if (response.success) {
      navigate(`/projectdetails`, {
        state: {
          projectData: project,
          responseData: response.data,
        },
      });
    }
  };

  const handleDeleteProject = async (projectId, event) => {
    event.stopPropagation();
    const data = {
      project_id: projectId.toString(),
    };
    const response = await Delete_Project(data);
    if (response.success) {
      fetchProjects();
      toast.current.show({
        severity: "success",
        summary: "Delete Success",
        detail: "Project deleted successfully",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Delete Failed",
        detail: response.message,
        life: 4000,
      });
    }
  };

  const toggleOptionsMenu = (index, event) => {
    event.stopPropagation();
    setOptionsMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setNewProject({
      ...newProject,
      file: e.target.files[0],
    });
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const fetchProjects = async () => {
    setOpen(true);
    try {
      const response = await test_automation_projects();
      if (response.success) {
        setProjects(response.data);
        console.log("Projects fetched:", response.data);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      showError(error);
      console.error("Failed to fetch projects:", error);
    }
    setOpen(false);
  };

  const showError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 4000,
    });
  };
  
  const handleCreateProject = async () => {
    if (newProject.projectName && newProject.file) {
      const formData = new FormData();
      formData.append("application_name", newProject.projectName);
      formData.append("files", newProject.file);
      setShowModal(false);
      setIsCreatingProject(true); // Start showing spinner
      setCreationInProgress(true); // Mark creation as in progress
      localStorage.setItem("creationInProgress", "true"); // Save to localStorage
  
      try {
        const response = await Post_test_automation_projects(formData);
        if (response.success) {
          console.log("Project created successfully:", response);
          localStorage.removeItem("creationInProgress");
          setIsCreatingProject(false); // Stop showing spinner in all cases
          setCreationInProgress(false); // Mark creation as not in progress in all cases
          fetchProjects(); // Refetch projects to update the list
        
          setNewProject({ projectName: "", file: null }); // Reset form state
          window.location.reload(); 
         
        } else {
          console.error("Failed to create project:", response.message);
        }
      } catch (error) {
        console.error("Error creating project:", error);
      } finally {
        localStorage.removeItem("creationInProgress"); // Clear localStorage in all cases
        setIsCreatingProject(false); // Stop showing spinner in all cases
        setCreationInProgress(false); // Mark creation as not in progress in all cases
       
      }
    } else {
      alert("Please fill all fields and upload a file."); // Basic validation feedback
    }
  };
  
  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.dashboard}>
        <div className={styles.all}>
          <div className={styles.box} onClick={openModal}>
            <p>+ Create New Project</p>
          </div>

         
          {projects.map((project, index) => (
            <div
              className={styles.project_info}
              key={index}
              onClick={(event) => handleProjectClick(project, event)}
            >
              <div className={styles.firstRow}>
                <div className={styles.childRow}>
                  <div className={styles.imggback}>
                    <img
                      src={card_icon}
                      alt="Project Icon"
                      className={styles.card_icon}
                    />
                  </div>
                  <h3 className={styles.info_header}>
                    {project.application_name}
                  </h3>
                </div>
                <FontAwesomeIcon icon={faTrash}  onClick={(event) =>
                      handleDeleteProject(project.project_id, event)
                    } className={styles.trashIcon} />
              </div>
              {/* {optionsMenuIndex === index && (
                <div className={styles.options_menu}>
                  <div
                    className={styles.delete_option}
                    onClick={(event) =>
                      handleDeleteProject(project.project_id, event)
                    }
                  >
                    Delete
                  </div>
                </div>
              )} */}
              <p className={styles.url_p1}>
                <span className={styles.testPlanLabel}>
                  Requirements Document :
                </span>
                <span className={styles.fileName}>{project.prd_document}</span>
              </p>
            </div>
          ))}
 {isCreatingProject || creationInProgress ? ( // Check either state
            <div className={styles.project_info}>
              {/* <div className={styles.spinnerContainer}>
                <div className={styles.spinner}></div>
              </div> */}
    <NewLoading widtht="100px" heightt="100px"/>
              <p className={styles.creatingText}>Creating project...</p>
            </div>
          ) : null}

          {/* Modal for creating/updating project */}
          <div className={styles.body}>
                     <Modal open={showModal} onClose={closeModal}>
               <div className={styles.modal_overlay}>
                 <div className={styles.overlay_header}>
                   <h5>Create New Project</h5>
                   <p className={styles.close_btn} onClick={closeModal}>
                    X
                   </p>
                 </div>
                 <div>
                   <div
                     className={styles.form}
                     onSubmit={(e) => {
                       e.preventDefault();
                       handleCreateProject();
                   }}
                   >
                     <div className={styles.form_field}>
                       <p className={styles.projectname}>Application Name</p>
                       <input
                         className={styles.inputbox_Name}
                         placeholder="Application Name"
                         type="text"
                                                  value={newProject.projectName}
                         onChange={(e) =>
                           setNewProject({
                             ...newProject,
                             projectName: e.target.value,
                           })
                         }
                       />
                     </div>
                   
                   </div>
                   <div className={styles.upload_section}>
                     <div className={styles.upload_button}>
                       <button
                         className={styles.fileUploadButton}
                                                  type="button"
                       onClick={handleFileUploadClick}
                       >
                         <img
                           src={vectorImage}
                           alt=""
                           className={styles.buttonIcon}
                         />
                         Upload Requirements Document
                       </button>
                     </div>
                     <div className={styles.selected_file_text}>
                       <input
                         ref={fileInputRef}
                         type="file"
                         style={{ display: "none" }}
                        onChange={handleFileChange}
                         accept=".pdf, .doc, .docx, .txt"
                       />
                       {newProject.file ? (
                         <p className={styles.selected_file_name}>
                           Selected file: {newProject.file.name}
                         </p>
                       ) : (
                                                 <p className={styles.no_file_selected}>
                         No file selected
                        </p>                       )}
                    </div>                   </div>
                  <div className={styles.modal_buttons}>
                     <button
                       className={styles.submit_btn}
                       type="submit"
                       onClick={()=>handleCreateProject(isCreatingProject,creationInProgress)}
                     >
                       Submit
                     </button>
                     <button
                       className={styles.cancel_btn}
                       type="button"
                       onClick={closeModal}
                     >
                       Cancel
                     </button>
                   </div>
                 </div>
               </div>
             </Modal> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestProjects;
