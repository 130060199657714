import axios from "axios";
import {
  backend_url,
  backend_urlT,
  backend_url8001,
  backend_url5000,
  frontend_url,
  dataVizard,
  Optilogistics_url,
  Content_url,
  Content_url2,
  Content_url3,
} from "./config";
// import textToImage from "./config"
// import { Header } from "./commonVariable";

// axios.get(url, Header);
// axios.post(url,DATA, Header);
// axios.put(url, DATA, Header);
// axios.delete(url, { data: DATA, headers: { "Authorization": `Bearer ${token}` } });
let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2FjY291bnRfaWQiOjE2LCJ1c2VyX2ZpcnN0bmFtZSI6IkxMTU9QUyIsInVzZXJfbGFzdF9uYW1lIjoiTUxBTkdMRVMiLCJkZXBhcnRtZW50IjoiRGlnaXRhbCBFY28tU3lzdGVtIiwiYnVzaW5lc3NfdW5pdCI6IkRldmVsb3BlciIsImlzX2FjdGl2ZSI6IjEiLCJhc3NpZ25fcm9sZSI6IiIsImVtYWlsIjoibGxtb3BzbWxhbmdsZXNAY2xvdWRhbmdsZXMuY29tIiwidXNlcl9tb2JpbGUiOiI4OTc3NDg2MzUyIiwidXNlcl9vcmdhbml6YXRpb24iOiJDbG91ZCBBbmdsZXMiLCJwYXNzd29yZCI6Ik1sYW5nbGVzQDEyMyIsImlzX3N1cGVydXNlciI6IjEifQ.DXnr5kJgEuVXI-3EgoQc8BzPtOn3u3L9lMp93GL75pY"
// let token = JSON.parse(localStorage.getItem("genAi"));

function commonHeader() {
  let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2FjY291bnRfaWQiOjE2LCJ1c2VyX2ZpcnN0bmFtZSI6IkxMTU9QUyIsInVzZXJfbGFzdF9uYW1lIjoiTUxBTkdMRVMiLCJkZXBhcnRtZW50IjoiRGlnaXRhbCBFY28tU3lzdGVtIiwiYnVzaW5lc3NfdW5pdCI6IkRldmVsb3BlciIsImlzX2FjdGl2ZSI6IjEiLCJhc3NpZ25fcm9sZSI6IiIsImVtYWlsIjoibGxtb3BzbWxhbmdsZXNAY2xvdWRhbmdsZXMuY29tIiwidXNlcl9tb2JpbGUiOiI4OTc3NDg2MzUyIiwidXNlcl9vcmdhbml6YXRpb24iOiJDbG91ZCBBbmdsZXMiLCJwYXNzd29yZCI6Ik1sYW5nbGVzQDEyMyIsImlzX3N1cGVydXNlciI6IjEifQ.DXnr5kJgEuVXI-3EgoQc8BzPtOn3u3L9lMp93GL75pY";
  // let token = JSON.parse(localStorage.getItem("genAi"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };
  console.log("header",Header);
  return Header;
}


// import Cookies from 'universal-cookie';

// const cookies = new Cookies();
// const cookieToken = cookies.get("genAi");
// console.log("cookieToken",cookieToken);
// // Parse the token back to its original format if it was stringified
// const token = cookieToken ? JSON.parse(cookieToken) : null;

// console.log("token",token); // This will log the token from cookies if it exists
// function commonHeader() {
//   const token = cookieToken ? JSON.parse(cookieToken) : null;
//   let Header = { headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2FjY291bnRfaWQiOjE1MSwidXNlcl9maXJzdG5hbWUiOiJsbG1vcHMiLCJ1c2VyX2xhc3RfbmFtZSI6Im1sYW5nbGVzIiwiZGVwYXJ0bWVudCI6Ik1MYW5nbGVzIiwiYnVzaW5lc3NfdW5pdCI6IiIsImlzX2FjdGl2ZSI6IjEiLCJhc3NpZ25fcm9sZSI6InBsYXRmb3JtLWFkbWluIiwiZW1haWwiOiJsbG1vcHNtbGFuZ2xlc0BjbG91ZGFuZ2xlcy5jb20iLCJ1c2VyX21vYmlsZSI6IjYzMDQyMjk1MzUiLCJ1c2VyX29yZ2FuaXphdGlvbiI6ImNsb3VkYW5nbGVzIiwicGFzc3dvcmQiOiJNbGFuZ2xlc0AxMjMiLCJpc19zdXBlcnVzZXIiOiIxIn0.jkjuCrPj5CnlBC_DWZM8TMRb0QjXkIz1pr0yd-n4VcU` } };
//   console.log("header",Header);
//   return Header;
// }
export async function loginAPI(data) {
  try {
    const res = await axios.post(`${backend_url}/login/login`, data);
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in loginApi" };
  }
}



export async function TestautomationAPI(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/testautomation`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in testautomation" };
  }
}




export async function getTestRunsAPI(data) {
  try {
    const res = await axios.get(
      `${backend_urlT}/testautomation/session/get_session_ids`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in routeApi" };
  }
}

export async function PostsessionId(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/session/session_details`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in PostsessionId" };
  }
}

export async function DeletesessionId(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/session/delete`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in PostsessionId" };
  }
}









export async function TrackRun(data) {
  try {
    const res = await axios.post(
      `${Optilogistics_url}/track`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostUplaodExcelApi" };
  }
}

export async function DeleteRun(DATA) {
  try {
    const res = await axios.delete(`${Optilogistics_url}/delete_runs`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteSessionAPI" };
  }
}

export async function PostEmail(data) {
  try {
    const res = await axios.post(
      `${Optilogistics_url}/email`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostEmail" };
  }
}



// ("<-------------------Content Generation Api's------------------->");

export async function PostProject(data) {
  try {
    const res = await axios.post(
      `${Content_url}/content-generation/project`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

export async function GetProject() {
  try {
    const res = await axios.get(
      `${Content_url}/content-generation/get-projects`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetGetProject" };
  }
}

export async function PostTemplate(data, projectId) {
  try {
    const res = await axios.post(
      `${Content_url}/content-generation/template/${projectId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

export async function GetTemplate(projectId) {
  try {
    const res = await axios.get(
      `${Content_url}/content-generation/get-templates/${projectId}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetGetProject" };
  }
}

export async function ContentdeleteProject(projectId) {
  try {
    const res = await axios.delete(
      `${Content_url}/content-generation/delete-project/${projectId}`,
      {
        // data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in ContentdeleteProject" };
  }
}

export async function Contentdeletetemplate(projectId) {
  try {
    const res = await axios.delete(
      `
${Content_url}/content-generation/delete-template/${projectId}`,
      {
        // data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in ContentdeleteProject" };
  }
}

export async function GetTittle(TemplateID) {
  try {
    const res = await axios.get(
      `${Content_url2}/content-generation-title/get-stage-data/${TemplateID}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetTittle" };
  }
}

export async function Generate_Tittle(data) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/generate-title`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

export async function Save_Tittle(data) {
  try {
    const res = await axios.put(
      `${Content_url2}/content-generation-title/save-title`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

export async function Generate_Outliners(data) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/generate-outlines`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

export async function Save_Outline(data, projectId) {
  try {
    const res = await axios.put(
      `${Content_url2}/content-generation-title/save-outlines/${projectId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in saveConfluenceAPI" };
  }
}

// ${Content_url2}/content-generation-title/generate-key-points

export async function Generate_Keypoints(data, projectId) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/generate-key-points/${projectId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

// export async function GetRoles(data) {
//   try {
//     const res = await axios.get(
//       `${Content_url2}/content-generation-title/get-role`,
//       data,
//       commonHeader()
//     );
//     return res.data;
//   } catch (e) {
//     console.log("error---", e);
//     return { sucess: false, message: "Error in GetTittle" };
//   }
// }

export async function GetRoles() {
  try {
    const res = await axios.get(
      `${Content_url2}/content-generation-title/get-role`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetGetProject" };
  }
}

export async function PostRoles(data) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/add-role`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostRoles" };
  }
}
export async function PostMagicQuery(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/magicprompt`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostMagicQuery" };
  }
}

export async function PostMagicImageQuery(data, templateId) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/image-generation/${templateId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostMagicImageQuery" };
  }
}

export async function PostQuery(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/text-generation`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostQuery" };
  }
}

export async function GetImages() {
  try {
    const res = await axios.get(
      `${Content_url3}/content-generation-image/get-all-images`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetImages" };
  }
}

export async function GetContent(templateId) {
  try {
    const res = await axios.get(
      `${Content_url2}/content-generation-title/get-stage-data/${templateId}`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetContent" };
  }
}

export async function ReplaceAPI(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/editing`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostQuery" };
  }
}

export async function GetBlogRoles() {
  try {
    const res = await axios.get(
      `${Content_url3}/content-generation-image/roles`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in GetBlogRoles" };
  }
}

export async function SaveDoc(data, templateId) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/save-content/${templateId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostQuery" };
  }
}

export async function postGmail(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/gmailpost`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postGmail" };
  }
}
export async function Keypoints_Finish(data, projectId) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/save-document/${projectId}`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostProject" };
  }
}

export async function Savefile(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/uploadfile`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postGmail" };
  }
}

export async function PostMedium(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/mediumpost`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postGmail" };
  }
}

export async function PostLinkeIn(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/linkedinpost`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostMedium" };
  }
}

export async function PostAddrole(data) {
  try {
    const res = await axios.post(
      `${Content_url3}/content-generation-image/create-roles`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostMedium" };
  }
}

export async function test_automation_projects() {
  try {
    const res = await axios.get(
      `${backend_urlT}/testautomation/test_automation_projects`,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Get test_automation_projects" };
  }
}
export async function Post_test_automation_projects(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/test_automation_projects`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostAiSessions" };
  }
}

export async function Delete_Project(DATA) {
  try {
    const res = await axios.delete(
      `${backend_urlT}/testautomation/test_automation_projects`,
      {
        data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in deleteActorRoleAPI" };
  }
}

export async function get_all_features(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/get_all_features`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get_all_features" };
  }
}

export async function generate_test_cases(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/generate_test_cases`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get_all_features" };
  }
}

export async function get_all_test_cases(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/get_all_test_cases`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get_all_test_cases" };
  }
}

export async function get_output(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/execute_test_scripts`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get_output" };
  }
}

export async function modify_test_case(data) {
  try {
    const res = await axios.put(
      `${backend_urlT}/testautomation/modify_test_case`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in modify_test_case" };
  }
}

export async function Delete_Testcase(DATA) {
  try {
    const res = await axios.delete(
      `${backend_urlT}/testautomation/modify_test_case`,
      {
        data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete_Testcase" };
  }
}

export async function Create_test_case(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/modify_test_case`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Create_test_case" };
  }
}

export async function generate_test_script(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/generate_test_script`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in generate_test_script" };
  }
}

export async function save_test_script(data) {
  try {
    const res = await axios.put(
      `${backend_urlT}/testautomation/generate_test_script`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in save_test_script" };
  }
}

export async function Delete_Testscript(DATA) {
  try {
    const res = await axios.delete(
      `${backend_urlT}/testautomation/generate_test_script`,
      {
        data: DATA,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete_Testcase" };
  }
}


export async function EditTruck(data) {
  try {
    const res = await axios.post(
      `${Optilogistics_url}/modify_deliveries`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in PostUplaodExcelApi" };
  }
}

export async function Delete_Delivery(DATA) {
  try {
    const res = await axios.delete(`${Optilogistics_url}/delete_deliveries`, {
      data: DATA,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (e) {
    console.log("error--", e);
    return { success: false, message: "Error in Delete_Testcase" };
  }
}

export async function regenerate_outlines(data) {
  try {
    const res = await axios.post(
      `${Content_url2}/content-generation-title/regenerate-outlines
`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in regenerate_outlines" };
  }
}
export async function get_all_test_plans(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/get_all_test_plans`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in get_all_test_plans" };
  }
}

export async function PostTeststrategy(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/get_test_strategy`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in postNewSessionDataPro" };
  }
}

export async function generate_test_plans(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/generate_test_plans`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in generate_test_plans" };
  }
}

export async function fetchTestCaseType(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/get_all_test_tags`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in fetchTestCaseType" };
  }
}



export async function addtesttags(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/add_test_tags`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in add_test_tags" };
  }
}
export async function removeTestTags(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/delete_test_tags`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in delete_test_tags" };
  }
}


export async function Querychatbot(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/query_chatbot`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Querychatbot" };
  }
}



export async function Querychatbothistory(data) {
  try {
    const res = await axios.post(
      `${backend_urlT}/testautomation/get_chat`,
      data,
      commonHeader()
    );
    return res.data;
  } catch (e) {
    console.log("error---", e);
    return { sucess: false, message: "Error in Querychatbot" };
  }
}
