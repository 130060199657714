import React, { useRef, useState, useEffect } from "react";
import stylesD from "../AutomationTest/projectdetails.module.css";
import { useParams, useLocation } from "react-router-dom";
import {
  Create_test_case,
  Delete_Testcase,
  Delete_Testscript,
  generate_test_cases,
  generate_test_script,
  get_all_test_cases,
  get_all_test_plans,
  modify_test_case,
  save_test_script,
} from "../commonApi";
import Charging from "../Charging";
import { Toast } from "primereact/toast";
import arrow from "../assests/images/appstore/arrow.png";
import jsPDF from "jspdf";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
const ProjectDetails = (project_id) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [testCaseId, setTestCaseId] = useState("");
  const [testCaseTitle, setTestCaseTitle] = useState("");
  const [preconditions, setPreconditions] = useState("");
  const [testSteps, setTestSteps] = useState("");
  const [featuresdata, setFeatures] = useState("");
  const toast = useRef(null);
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const projectData = location.state?.projectData;
  console.log("------------projectdata", projectData);

  // const { project_id } = useParams();
  const [testCasesByFeature, setTestCasesByFeature] = useState({});

  const [editingTestCase, setEditingTestCase] = useState(null);

  const [testData, setTestData] = useState("");
  const [expectedResult, setExpectedResult] = useState("");
  const [currentFeatureId, setCurrentFeatureId] = useState(null); // For tracking the feature ID
  const [openTestScripts, setOpenTestScripts] = useState({});
  const [editingTestScriptId, setEditingTestScriptId] = useState(null);
  const [editedTestScriptContent, setEditedTestScriptContent] = useState("");
  const [expandedFeatures, setExpandedFeatures] = useState({});
  const [openTestScriptCaseId, setOpenTestScriptCaseId] = useState(null);
  const [Testplan, setTestplan] = useState("");

  const handleEditClick = (testCase) => {
    setEditingTestCase(testCase);
  };

  const handleEditTestScript = (testCase) => {
    setEditingTestScriptId(testCase.Test_Case_ID);
    setEditedTestScriptContent(testCase.Test_Script || "");
  };
  const handleDownloadTestScript = (testCase) => {
    // Determine the test script content
    const text =
      editingTestScriptId === testCase.Test_Case_ID
        ? editedTestScriptContent
        : testCase.Test_Script;

    console.log("Text to be added to file:", text); // Debugging line

    if (!text) {
      console.error("No test script content available to download.");
      return;
    }

    // Create a blob with the text content
    const blob = new Blob([text], { type: "text/plain;charset=utf-8" });

    // Create an object URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const a = document.createElement("a");
    a.href = url;
    a.download = `test_script_${testCase.Test_Case_ID}.py`;

    // Append the link to the body
    document.body.appendChild(a);

    // Programmatically click the link to trigger the download
    a.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (Array.isArray(featuresdata)) {
      const initialExpandedState = {};
      featuresdata.forEach((feature) => {
        initialExpandedState[feature.test_plan_id] = false;
      });
      setExpandedFeatures(initialExpandedState);
    } else {
      console.error("featuresdata is not an array:", featuresdata);
    }
  }, [featuresdata]);

  const handleSaveTestScript = async (testCase) => {
    setOpen(true); // Show loading indicator
    try {
      const data = {
        tc_id: testCase.tc_id,
        test_script: editedTestScriptContent,
      };
      const response = await save_test_script(data);

      if (response.success) {
        // Fetch updated test cases for the feature
        const testCasesResponse = await get_all_test_cases({
          test_plan_id: testCase.test_plan_id,
        });

        if (testCasesResponse.success) {
          setTestCasesByFeature((prev) => ({
            ...prev,
            [testCase.test_plan_id]: testCasesResponse.features,
          }));
        } else {
          console.error(
            "Failed to fetch test cases:",
            testCasesResponse.message
          );
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to fetch updated test cases",
          });
          return;
        }

        // Clear editing state
        setEditingTestScriptId(null);
        setEditedTestScriptContent("");

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Test script saved successfully",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to save test script",
        });
      }
    } catch (error) {
      console.error("Error saving test script:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while saving the test script",
      });
    } finally {
      setOpen(false);
    }
  };

  const handleAddTestCaseClick = (featureId) => {
    setModalOpen(true);
    setCurrentFeatureId(featureId);
  };

  useEffect(() => {
    const fetchData = async () => {
      setOpen(true);
      try {
        const featuresResult = await get_all_test_plans({
          project_id: projectData.project_id,
        });
        if (featuresResult.success) {
          setFeatures(featuresResult.data); // Store feature data
          console.log("------------feteature result", featuresdata);

          setTestplan(featuresResult.test_plan.test_plan);
          // Initialize openTestScripts
          console.log("---------------ssfcsdc");
          let initialOpenTestScripts = {};

          // Automatically load test cases for features where they have already been generated
          for (const feature of featuresResult.data) {
            if (feature.is_test_case_generated) {
              const testCasesResponse = await get_all_test_cases({
                test_plan_id: feature.test_plan_id,
              });
              console.log("---------------tescaseresponse", testCasesResponse);
              if (testCasesResponse.success) {
                const testCases = testCasesResponse.features;
                setTestCasesByFeature((prev) => ({
                  ...prev,
                  [feature.test_plan_id]: testCases,
                }));
                console.log("------------testcasesfeature", testCasesByFeature);

                // Initialize openTestScripts based on is_test_script_generated
                testCases.forEach((tc) => {
                  if (tc.is_test_script_generated) {
                    initialOpenTestScripts[tc.Test_Case_ID] = true;
                  }
                });
              }
            }
          }

          setOpenTestScripts(initialOpenTestScripts);
        } else {
          console.error("Failed to fetch features:", featuresResult.message);
        }
      } catch (error) {
        console.error("Error fetching features:", error);
      } finally {
        setOpen(false);
      }
    };

    fetchData();
  }, [projectData.project_id]);

  const handleTestCaseChange = (e, featureId, idx, field) => {
    const updatedTestCases = [...testCasesByFeature[featureId]];
    let value = e.target.value;

    // Special handling for Test_Steps and Test_Data
    if (field === "Test_Steps") {
      value = value.split("\n");
    } else if (field === "Test_Data") {
      value = parseTestData(value);
    }

    updatedTestCases[idx] = {
      ...updatedTestCases[idx],
      [field]: value,
    };
    setTestCasesByFeature((prev) => ({
      ...prev,
      [featureId]: updatedTestCases,
    }));
  };

  const handleSaveTestCase = async (featureId, idx) => {
    setOpen(true);
    try {
      const testCase = testCasesByFeature[featureId][idx];
      const data = {
        tc_id: testCase.tc_id,
        Test_Case_ID: testCase.Test_Case_ID,
        Test_Case_Title: testCase.Test_Case_Title,
        Preconditions: testCase.Preconditions,
        Test_Steps: testCase.Test_Steps,
        Test_Data: testCase.Test_Data,
        Expected_result: testCase.Expected_result,
      };

      const response = await modify_test_case(data);

      if (response.success) {
        // Update local state
        setTestCasesByFeature((prev) => ({
          ...prev,
          [featureId]: prev[featureId].map((tc) =>
            tc.Test_Case_ID === testCase.Test_Case_ID ? testCase : tc
          ),
        }));

        // Refresh all features
        const featuresResult = await get_all_test_plans({
          project_id: projectData.project_id,
        });
        if (featuresResult.success) {
          setFeatures(featuresResult.data);
        }

        // Clear the editing state after a successful save
        setEditingTestCase(null);

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Test case updated successfully",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update test case",
        });
      }
    } catch (error) {
      console.error("Error saving test case:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while saving the test case",
      });
    } finally {
      setOpen(false);
    }
  };

  const handleDeleteTestCase = async (testCase) => {
    setOpen(true);
    try {
      const response = await Delete_Testcase({ tc_id: testCase.tc_id });
      if (response.success) {
        // Remove the deleted test case from the local state
        setTestCasesByFeature((prev) => ({
          ...prev,
          [testCase.test_plan_id]: prev[testCase.test_plan_id].filter(
            (tc) => tc.tc_id !== testCase.tc_id
          ),
        }));

        // Refresh all features
        const featuresResult = await get_all_test_plans({
          project_id: projectData.project_id,
        });
        if (featuresResult.success) {
          setFeatures(featuresResult.data);
        }

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Test case deleted successfully",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete test case",
        });
      }
    } catch (error) {
      console.error("Error deleting test case:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while deleting the test case",
      });
    } finally {
      setOpen(false);
    }
  };

  const handleGenerateTestCases = async (projectId, featureId) => {
    setOpen(true); // Assuming this manages a loading indicator
    try {
      const generationResponse = await generate_test_cases({
        project_id: projectId,
        test_plan_id: featureId,
      });
      if (generationResponse.success) {
        // Update the is_test_case_generated flag for the feature in featuresdata
        setFeatures((prevFeaturesdata) =>
          prevFeaturesdata.map((feature) =>
            feature.test_plan_id === featureId
              ? { ...feature, is_test_case_generated: true }
              : feature
          )
        );

        const testCasesResponse = await get_all_test_cases({
          test_plan_id: featureId,
        });
        if (testCasesResponse.success) {
          setTestCasesByFeature((prev) => ({
            ...prev,
            [featureId]: testCasesResponse.features,
          }));
        } else {
          console.error(
            "Failed to fetch test cases:",
            testCasesResponse.message
          );
        }
      } else {
        console.error(
          "Failed to generate test cases:",
          generationResponse.message
        );
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      setOpen(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const fetchTestCasesForFeature = async (featureId) => {
    const testCasesResponse = await get_all_test_cases({
      test_plan_id: featureId,
    });
    if (testCasesResponse.success) {
      setTestCasesByFeature((prev) => ({
        ...prev,
        [featureId]: testCasesResponse.features,
      }));
    } else {
      console.error("Failed to fetch test cases:", testCasesResponse.message);
    }
  };

  const handleDeleteTestScript = async (testCase) => {
    // Confirm deletion with the user

    setOpen(true); // Show loading indicator
    try {
      const data = {
        tc_id: testCase.tc_id,
      };
      const response = await Delete_Testscript(data);

      if (response.success) {
        // Fetch updated test cases for the feature
        const testCasesResponse = await get_all_test_cases({
          test_plan_id: testCase.test_plan_id,
        });

        if (testCasesResponse.success) {
          setTestCasesByFeature((prev) => ({
            ...prev,
            [testCase.test_plan_id]: testCasesResponse.features,
          }));

          // Update openTestScripts state to close the deleted test script
          setOpenTestScripts((prev) => {
            const newOpenTestScripts = { ...prev };
            delete newOpenTestScripts[testCase.Test_Case_ID];
            return newOpenTestScripts;
          });

          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Test script deleted successfully",
          });
        } else {
          console.error(
            "Failed to fetch test cases:",
            testCasesResponse.message
          );
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to fetch updated test cases",
          });
          return;
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to delete test script",
        });
      }
    } catch (error) {
      console.error("Error deleting test script:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while deleting the test script",
      });
    } finally {
      setOpen(false);
    }
  };

  const handleGenerateTestScriptClick = async (testCase) => {
    setOpen(true); // Show loading indicator
    try {
      // Call API to generate test script
      const response = await generate_test_script({
        tc_id: testCase.tc_id,
        project_id: projectData.project_id,
        test_plan_id: testCase.test_plan_id,
      });

      if (response.success) {
        // Fetch updated test cases for the feature
        const testCasesResponse = await get_all_test_cases({
          test_plan_id: testCase.test_plan_id,
        });

        if (testCasesResponse.success) {
          const updatedTestCases = testCasesResponse.features;
          setTestCasesByFeature((prev) => ({
            ...prev,
            [testCase.test_plan_id]: updatedTestCases,
          }));

          // Update openTestScripts to show the newly generated script
          setOpenTestScripts((prev) => ({
            ...prev,
            [testCase.Test_Case_ID]: true,
          }));
        } else {
          console.error(
            "Failed to fetch test cases:",
            testCasesResponse.message
          );
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to fetch updated test cases",
          });
          return;
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to generate test script",
        });
        return;
      }
    } catch (error) {
      console.error("Error generating test script:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while generating the test script",
      });
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setOpen(true);
      try {
        const featuresResult = await get_all_test_plans({
          project_id: projectData.project_id,
        });
        if (featuresResult.success) {
          setFeatures(featuresResult.data); // Store feature data
          // Automatically load test cases for features where they have already been generated
          featuresResult.data.forEach((feature) => {
            if (feature.is_test_case_generated) {
              (async () => {
                const testCasesResponse = await get_all_test_cases({
                  test_plan_id: feature.test_plan_id,
                });
                if (testCasesResponse.success) {
                  setTestCasesByFeature((prev) => ({
                    ...prev,
                    [feature.test_plan_id]: testCasesResponse.features,
                  }));
                }
              })();
            }
          });
        } else {
          console.error("Failed to fetch features:", featuresResult.message);
        }
      } catch (error) {
        console.error("Error fetching features:", error);
      } finally {
        setOpen(false);
      }
    };

    fetchData();
  }, [projectData.project_id]);

  const formatTestData = (testData) => {
    if (typeof testData === "string") {
      // If it's already a string, return it as is
      return testData;
    } else if (typeof testData === "object" && testData !== null) {
      // If it's an object, convert it to a string
      return Object.entries(testData)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n");
    } else {
      // If it's neither a string nor an object, return an empty string
      return "";
    }
  };

  const parseTestData = (testDataString) => {
    // Convert the string back to an object
    const lines = testDataString.split("\n");
    return Object.fromEntries(
      lines.map((line) => {
        const [key, ...valueParts] = line.split(":");
        return [key.trim(), valueParts.join(":").trim()];
      })
    );
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Process 'Preconditions' and 'Test Steps' into arrays
    const testStepsArray = testSteps.split("\n").map((item) => item.trim());
    const testDataArray = testData.split("\n").map((item) => item.trim());

    // Manually format arrays into strings with single quotes
    const testStepsString = `[${testStepsArray.join("', '")}]`;
    const testDataString = `[${testDataArray.join("', '")}]`;

    // Process 'Test Data' into an object
    let testDataObject = {};
    testData.split("\n").forEach((line) => {
      const [key, value] = line.split(":").map((item) => item.trim());
      if (key && value) {
        testDataObject[key] = value;
      }
    });

    // Construct the data object
    const data = {
      test_plan_id: currentFeatureId,
      Test_Case_ID: testCaseId,
      Test_Case_Title: testCaseTitle,
      Preconditions: preconditions, // Assuming this can stay as a string
      Test_Steps: testStepsString, // Stringified array
      Test_Data: testDataString, // Stringified array
      Expected_result: expectedResult,
    };

    try {
      setOpen(true);
      const response = await Create_test_case(data);
      if (response.success) {
        // Refresh test cases after successful creation
        await fetchTestCasesForFeature(currentFeatureId);

        // Reset form fields
        setTestCaseId("");
        setTestCaseTitle("");
        setPreconditions("");
        setTestSteps("");
        setTestData("");
        setExpectedResult("");

        // Close the modal
        handleCloseModal();

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Test case created successfully",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to create test case",
        });
      }
    } catch (error) {
      console.error("Error creating test case:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while creating the test case",
      });
    } finally {
      setOpen(false);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={stylesD.fixheight}>
        {/* <div className={stylesD.project}>
          <h5>Project Details</h5>
        </div> */}
        <div className={stylesD.application}>
          <h5>Application Name: {projectData?.application_name}</h5>
          {/* <h5>Url of the application: {projectData?.url}</h5> */}
          {/* <h5>
            3 Test plan Document:
            {projectData?.test_plan_document || "No document uploaded"}
          </h5> */}
        </div>

        <div className={stylesD.feature}>Test Plan</div>
        <div className={stylesD.testPlan}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{Testplan}</ReactMarkdown>
        </div>

        {featuresdata &&
          featuresdata?.map((feature, index) => (
            <div key={index}>
              <div className={stylesD.feature}>
                <h5>Jira User Stories</h5>
              </div>
              <div className={stylesD.insidefeature}>
                <h5>
                  {/* {index + 1}. Test Plan ID: {feature.test_plan_id} */}
                  <br />
                  User Story: {feature.jira_story?.Summary}
                  <br />
                  Story ID: {feature.jira_story?.Story_ID}
                  <br />
                  Status: {feature.jira_story?.Status}
                  <br />
                  Assignee: {feature.jira_story?.Assignee}
                  <br />
                  <span style={{ whiteSpace: "pre-line" }}>
                    {feature.jira_story?.Description}
                  </span>
                </h5>

                <div className={stylesD.butto}>
                  {!feature.is_test_case_generated && ( // Render button only if test cases have not been generated
                    <button
                      className={stylesD.gen}
                      onClick={() =>
                        handleGenerateTestCases(
                          projectData?.project_id,
                          feature.test_plan_id
                        )
                      }
                    >
                      Generate TestCases
                    </button>
                  )}
                </div>

                {testCasesByFeature[feature.test_plan_id] && (
                  <div>
                    <div className={stylesD.testcase}>
                      <h5>Test Cases for {feature.jira_story?.Summary}</h5>
                      <div className={stylesD.addarrow}>
                        <button
                          className={stylesD.add}
                          onClick={() =>
                            handleAddTestCaseClick(feature.test_plan_id)
                          }
                        >
                          Add New Test Case
                        </button>
                        <img
                          src={arrow}
                          className={`${stylesD.arrow} ${
                            expandedFeatures[feature.test_plan_id]
                              ? stylesD.expanded
                              : ""
                          }`}
                          onClick={() => {
                            setExpandedFeatures((prev) => ({
                              ...prev,
                              [feature.test_plan_id]:
                                !prev[feature.test_plan_id],
                            }));
                          }}
                        />
                      </div>
                    </div>
                    {expandedFeatures[feature.test_plan_id] &&
                      testCasesByFeature[feature.test_plan_id].map(
                        (testCase, idx) => (
                          <div key={idx} className={stylesD.testcasefeature}>
                            <div>
                              <label>Test Case ID:</label>
                              <input
                                type="text"
                                value={testCase.Test_Case_ID}
                                className={stylesD.same}
                                onChange={(e) =>
                                  handleTestCaseChange(
                                    e,
                                    feature.test_plan_id,
                                    idx,
                                    "Test_Case_ID"
                                  )
                                }
                                readOnly={
                                  editingTestCase?.Test_Case_ID !==
                                  testCase.Test_Case_ID
                                }
                              />
                            </div>
                            <div>
                              <label>Test Case Title:</label>
                              <input
                                type="text"
                                value={testCase.Test_Case_Title}
                                className={stylesD.same}
                                onChange={(e) =>
                                  handleTestCaseChange(
                                    e,
                                    feature.test_plan_id,
                                    idx,
                                    "Test_Case_Title"
                                  )
                                }
                                readOnly={
                                  editingTestCase?.Test_Case_ID !==
                                  testCase.Test_Case_ID
                                }
                              />
                            </div>
                            <div>
                              <label>Preconditions:</label>
                              <textarea
                                value={testCase.Preconditions}
                                className={stylesD.editableTextArea}
                                onChange={(e) =>
                                  handleTestCaseChange(
                                    e,
                                    feature.test_plan_id,
                                    idx,
                                    "Preconditions"
                                  )
                                }
                                readOnly={
                                  editingTestCase?.Test_Case_ID !==
                                  testCase.Test_Case_ID
                                }
                              />
                            </div>
                            <div>
                              <label>Test Steps:</label>
                              <textarea
                                value={
                                  Array.isArray(testCase.Test_Steps)
                                    ? testCase.Test_Steps.join("\n")
                                    : testCase.Test_Steps
                                }
                                className={stylesD.editableTextArea}
                                onChange={(e) =>
                                  handleTestCaseChange(
                                    e,
                                    feature.test_plan_id,
                                    idx,
                                    "Test_Steps"
                                  )
                                }
                                readOnly={
                                  editingTestCase?.Test_Case_ID !==
                                  testCase.Test_Case_ID
                                }
                              />
                            </div>
                            <div>
                              <label>Test Data:</label>
                              <textarea
                                value={formatTestData(testCase.Test_Data)}
                                className={stylesD.editableTextArea}
                                onChange={(e) =>
                                  handleTestCaseChange(
                                    e,
                                    feature.test_plan_id,
                                    idx,
                                    "Test_Data"
                                  )
                                }
                                readOnly={
                                  editingTestCase?.Test_Case_ID !==
                                  testCase.Test_Case_ID
                                }
                              />
                            </div>
                            <div>
                              <label>Expected Result:</label>
                              <textarea
                                value={testCase.Expected_result}
                                className={stylesD.editableTextArea}
                                onChange={(e) =>
                                  handleTestCaseChange(
                                    e,
                                    feature.test_plan_id,
                                    idx,
                                    "Expected_result"
                                  )
                                }
                                readOnly={
                                  editingTestCase?.Test_Case_ID !==
                                  testCase.Test_Case_ID
                                }
                              />
                            </div>
                            <div className={stylesD.space}>
                              <div className={stylesD.threebutto}>
                                {editingTestCase?.Test_Case_ID ===
                                testCase.Test_Case_ID ? (
                                  <>
                                    <button
                                      className={stylesD.save}
                                      onClick={() =>
                                        handleSaveTestCase(
                                          feature.test_plan_id,
                                          idx
                                        )
                                      }
                                    >
                                      Save
                                    </button>
                                    <button
                                      className={stylesD.cancel}
                                      onClick={() => setEditingTestCase(null)}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className={stylesD.edit}
                                      onClick={() => handleEditClick(testCase)}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className={stylesD.delete}
                                      onClick={() =>
                                        handleDeleteTestCase(testCase)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </>
                                )}
                              </div>
                              <button
                                className={stylesD.gen}
                                onClick={() => {
                                  if (testCase.is_test_script_generated) {
                                    setOpenTestScripts((prev) => ({
                                      ...prev,
                                      [testCase.Test_Case_ID]:
                                        !prev[testCase.Test_Case_ID],
                                    }));
                                  } else {
                                    handleGenerateTestScriptClick(testCase);
                                  }
                                }}
                              >
                                {testCase.is_test_script_generated
                                  ? openTestScripts[testCase.Test_Case_ID]
                                    ? "Hide Test Script"
                                    : "Show Test Script"
                                  : "Generate Test Script"}
                              </button>
                            </div>
                            {openTestScripts[testCase.Test_Case_ID] && (
                              <div>
                                <div className={stylesD.testscript1}>
                                  <h5>
                                    Test Script for {testCase.Test_Case_Title}
                                  </h5>
                                </div>
                                <div className={stylesD.insidefeature}>
                                  {editingTestScriptId ===
                                  testCase.Test_Case_ID ? (
                                    // If editing, show textarea
                                    <textarea
                                      className={stylesD.testScriptTextarea}
                                      value={editedTestScriptContent}
                                      onChange={(e) =>
                                        setEditedTestScriptContent(
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    // If not editing, show pre-formatted text
                                    <pre className={stylesD.testScript}>
                                      {testCase.Test_Script}
                                    </pre>
                                  )}

                                  <div className={stylesD.space}>
                                    <div className={stylesD.threebutto}>
                                      {editingTestScriptId ===
                                      testCase.Test_Case_ID ? (
                                        // If editing, show Save and Cancel buttons
                                        <>
                                          <button
                                            className={stylesD.save}
                                            onClick={() =>
                                              handleSaveTestScript(testCase)
                                            }
                                          >
                                            Save
                                          </button>
                                          <button
                                            className={stylesD.cancel}
                                            onClick={() =>
                                              setEditingTestScriptId(null)
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </>
                                      ) : (
                                        // If not editing, show Edit and Delete buttons
                                        <>
                                          <button
                                            className={stylesD.edit}
                                            onClick={() =>
                                              handleEditTestScript(testCase)
                                            }
                                          >
                                            Edit
                                          </button>
                                          <button
                                            className={stylesD.delete}
                                            onClick={() =>
                                              handleDeleteTestScript(testCase)
                                            }
                                          >
                                            Delete
                                          </button>
                                        </>
                                      )}
                                    </div>
                                    <button
                                      className={stylesD.gen}
                                      onClick={() =>
                                        handleDownloadTestScript(testCase)
                                      }
                                    >
                                      Download
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      )}
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>

      {isModalOpen && (
        <div className={stylesD.modalOverlay}>
          <div className={stylesD.modalContent}>
            <h5>Add New Test Case</h5>
            <div className={stylesD.modelinside}>
              <form onSubmit={handleSave}>
                <div className={stylesD.formRow}>
                  <div className={stylesD.inputGroup}>
                    <label htmlFor="testCaseId">Test Case ID</label>
                    <input
                      type="text"
                      id="testCaseId"
                      value={testCaseId}
                      className={stylesD.inp}
                      onChange={(e) => setTestCaseId(e.target.value)}
                    />
                  </div>
                  <div className={stylesD.inputGroup}>
                    <label htmlFor="testCaseTitle">Test Case Title</label>
                    <input
                      type="text"
                      id="testCaseTitle"
                      value={testCaseTitle}
                      className={stylesD.inp}
                      onChange={(e) => setTestCaseTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className={stylesD.formRow}>
                  <div className={stylesD.inputGroup}>
                    <label htmlFor="preconditions">Preconditions</label>
                    <textarea
                      id="preconditions"
                      value={preconditions}
                      className={stylesD.inp}
                      onChange={(e) => setPreconditions(e.target.value)}
                    />
                  </div>
                  <div className={stylesD.inputGroup}>
                    <label htmlFor="testSteps">Test Steps</label>
                    <textarea
                      id="testSteps"
                      value={testSteps}
                      className={stylesD.inp}
                      onChange={(e) => setTestSteps(e.target.value)}
                    />
                  </div>
                </div>
                <div className={stylesD.formRow}>
                  <div className={stylesD.inputGroup}>
                    <label htmlFor="testData">Test Data</label>
                    <textarea
                      id="testData"
                      value={testData}
                      className={stylesD.inp}
                      onChange={(e) => setTestData(e.target.value)}
                    />
                  </div>

                  <div className={stylesD.inputGroup}>
                    <label htmlFor="expectedResult">Expected Result</label>
                    <textarea
                      id="expectedResult"
                      value={expectedResult}
                      className={stylesD.inp}
                      onChange={(e) => setExpectedResult(e.target.value)}
                    />
                  </div>
                </div>
                <div className={stylesD.buttonGroup}>
                  <button className={stylesD.savetest} type="submit">
                    Submit
                  </button>
                  <button
                    className={stylesD.cancel}
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
