import { Provider } from 'react-redux';
import './App.css';
import RoutingGenai from './routes';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import appStore from './store/Appstore';
import Charging from './Charging';

function App() {

  return (
    <Provider store={appStore}>
      <div className="App">
        <RoutingGenai />
      </div>
    </Provider>
  );
}

export default App;