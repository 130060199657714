import { createSlice } from "@reduxjs/toolkit";

const vizardslice = createSlice({
    name: "vizardSlice",
    initialState: {
        fullData: [1, 2],
    },
    reducers: {
        updateData: (state, action) => {
            state.fullData = action.payload
        }
    }
})

export const { updateData } = vizardslice.actions;
const vizardReducer = vizardslice.reducer;
export default vizardReducer;

