import React, { Suspense, lazy, useState } from "react";
import Loading from "../components/loading";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Layout from "../Layout";

import SameLayout from "../sameLayout";
import TestProjects from "../AutomationTest/TestProjects";

import { useSelector } from "react-redux";
import Charging from "../Charging";
import { element } from "prop-types";




import TestStrategy from "../AutomationTest/TestStrategy";




const RoutingGenai = () => {
  const loading = useSelector((store) => store.loadingErrorStore.loading);
  console.log(`loading--${loading}`);

  return (
    <>
      <Charging open={loading} />
      <Suspense fallback={<Loading />}>
        <Router>
          <Routing />
        </Router>
      </Suspense>
    </>
  );
};

const Routing = () => {
  const [c1, setC1] = useState(0);

  function counting(data) {
    setC1(data);
  }

  let MainRoutes = {
    // path: "/",
    // element: <Layout />,
    // children: [
    //   { path: "", element: <TestProjects /> },
    // ],
  };

  let sameRoutes = {
    path: "/",
    element: <SameLayout counting={counting} />,
    children: [

      
      {
        path: "/projectdetails",
        element: <TestStrategy />,
      },
      { path: "/projects", element: <TestProjects /> },

     
      // { path: "/appstore/TestAutomation", element: <TestAutomation /> },
     
      
     

    ],
  };

  return useRoutes([MainRoutes, sameRoutes]);
};

export default RoutingGenai;
