import React from "react";
import { Outlet } from "react-router";
import Header from "./components/header/Header";
// import Sidebar from "./components/sidebar/Sidebar";

const SameLayout = ({ counting }) => {
  function chageVar(data) {
    console.log("chageVar----", data);
    counting(data);
  }

  return (
    <div>
      <Header />
      {/* <Sidebar chageVar={chageVar} /> */}
      <div className="main">
        <Outlet />
      </div>
    </div>
  );
};

export default SameLayout;
