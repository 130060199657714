

import { createSlice } from "@reduxjs/toolkit";

const loadingAndError = createSlice({
    name: "loadingAndError",
    initialState: {
        loading: false,
        error: ""
    },
    reducers: {
        updatingLoading: (state, action) => {
            state.loading = action.payload
        },
        updatingerror: (state, action) => {
            state.error = action.payload
        },
    }
})

export const { updatingLoading, updatingerror } = loadingAndError.actions;
const loadingOrErrorReducer = loadingAndError.reducer;
export default loadingOrErrorReducer;